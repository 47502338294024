import {Alert, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import OfferForm from "../../../components/forms/OfferForm";

import OfferService from "../../../services/offer.service";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const OfferDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [offer, setOffer] = useState();
    const [translations, setTranslations] = useState([]);

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getOffer = useCallback(async (id) => {
        try {
            const data = await OfferService.getById(id);
            setOffer(data);
            setTranslations(data.nameTranslations);
        } catch (e) {
            update({...state, error: t("translation:offer.error.notFound")})
            navigate("/offer");
        }
    }, [navigate]);


    useEffect(() => {
        getOffer(params.id);
    }, [getOffer, params.id]);

    const updateOffer = async (data) => {
        data.nameTranslations = translations;
        try {
            await OfferService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    return (
        <>
            <Helmet helmetData={helmetData}>
                {offer?.name && <title>{offer?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/offer" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('offer.title')}</span>
                </Link>
            </div>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('offer.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('offer.error.update')}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:offer.titleDetails")}</h1>
                </Card.Header>
                <Card.Body>
                    <OfferForm translations={translations} offer={offer} onSubmit={updateOffer} details/>
                </Card.Body>
            </Card>
        </>
    );
}

export default OfferDetails;