import {Alert, Container} from "react-bootstrap";
import {Outlet, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";

import Sidebar from "../../components/nav/Sidebar";

import AuthService from "../../services/auth.service";
import Topbar from "../../components/nav/Topbar";
import {AppContext} from "../../context/AppContext";

const Main = () => {

    const navigate = useNavigate();

    const {state, update} = useContext(AppContext);

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            navigate("/login");
        }
    }, [navigate]);

    useEffect(() => {
        if (state.error) {
            setTimeout(() => {
                update({...state, error: ''})
            }, 7000);
        }
    }, [state.error]);

    return (
        <div id={"wrapper"}>
            <Sidebar/>
            <div id={"content-wrapper"} className={"d-flex flex-column"}>
                <div id={"content"}>
                    <Topbar/>
                    <Container fluid={true}>
                        <Alert variant="danger"
                               show={state.error !== ''}
                               dismissible
                               onClose={() => update({...state, error: ''})}>
                            {state.error}
                        </Alert>
                        <Outlet/>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default Main;
