import {fas} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useContext} from "react";
import {library} from "@fortawesome/fontawesome-svg-core";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {Nav} from "react-bootstrap";
import {AppContext} from "../../context/AppContext";
import NavList from "./NavList";

const Sidebar = () => {

    library.add(fas);

    const {pathname} = useLocation();

    const {t} = useTranslation();

    const {state, update} = useContext(AppContext);

    return (
        <Nav className={`navbar-nav d-none d-md-flex bg-gradient-primary sidebar sidebar-dark accordion ${state.collapsed && "toggled"}`}
             defaultActiveKey={pathname === "/" ? "/home" : `/${pathname.split("/")[1]}`}>

            <div className={"sidebar-brand d-flex align-items-center justify-content-center"}>
                <div className={"sidebar-brand-icon rotate-n-15"}>
                    <FontAwesomeIcon icon={"book-open"} size={"lg"}/>
                </div>
                <div className={"sidebar-brand-text mx-3"}>{t('general.title')}</div>
            </div>

            <NavList />

            <div className={"text-center d-none d-md-inline"}>
                <button className={"rounded-circle border-0"} id="sidebarToggle"
                        onClick={() => update({...state, collapsed: !state.collapsed})}>
                    <FontAwesomeIcon className={"text-light"} icon={state.collapsed ? "chevron-right" : "chevron-left"}
                                     size={"1x"}/>
                </button>
            </div>
        </Nav>
    );
}

export default Sidebar;
