import {t} from "i18next";
import {Button, Col, Form, FormGroup, Image, Row} from "react-bootstrap";
import BodyService from "../../services/body.service";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const RegionForm = ({region, onSubmit, details}) => {

    const [base64, setBase64] = useState();
    const [regionDetails, setRegionDetails] = useState({});

    const {register, handleSubmit, formState, reset, setValue} = useForm({mode: "all"});

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const removeImage = () => {
        setBase64(null);
        setValue('image', null);
        setValue('imageUrl', null);
        if (region) {
            setRegionDetails({...region, imageUrl: null});
        }
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        await onSubmit(data);
        if (!details) {
            reset();
            removeImage();
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (details && region) {
                reset({
                    ...region
                });
                setRegionDetails(region);
            }
        }
        fetchData();
    }, [region]);


    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:region.creation.fields.infoFields')}
            </p>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Form.Label>{t('translation:region.creation.fields.name')}* :</Form.Label>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="auto mt-2">
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.image')}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="right" effect="solid">
                            {t("translation:region.creation.fields.imageInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image")}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-3">
                {(regionDetails?.imageUrl || base64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <Image fluid thumbnail src={base64 || region?.imageUrl} alt={region?.name}/>
                </Col>
                }
                {(base64 || regionDetails?.imageUrl) && (
                    <Col className="align-self-end">
                        <Button variant="link" size="ls" onClick={removeImage}
                                className="border-primary">
                            <FontAwesomeIcon icon="fas fa-trash"/>
                        </Button>
                    </Col>
                )}
            </Row>
            <Button type="submit" className={"mt-4"} disabled={!formState.isValid}>
                {!details ? t('translation:region.creation.buttonCreate') : t('translation:region.creation.buttonUpdate')}
            </Button>
        </Form>
    );
}

export default RegionForm;