import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

const NavbarLink = ({icon, url, text, collapsed}) => {

    return (
        <div className={"col-4 col-md-12"}>
            <Nav.Item>
                <Nav.Link to={url} eventKey={url} as={Link}>
                    <FontAwesomeIcon icon={icon}/>
                    <span className={`${!collapsed && "ms-2"}`}>{text}</span>
                </Nav.Link>
            </Nav.Item>
        </div>
    );
}

export default NavbarLink;
