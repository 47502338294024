import {t} from "i18next";
import {Button, Col, Form, FormGroup, Image, Row} from "react-bootstrap";
import {useCallback, Fragment, useContext, useEffect, useState} from "react";
import BodyService from "../../services/body.service";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import CompanyService from "../../services/company.service";
import {AppContext} from "../../context/AppContext";

const CompanyForm = ({onSubmit}) => {

    const {register, reset, handleSubmit, formState, setValue} = useForm({mode: "all"});

    const {state, update} = useContext(AppContext);

    const [companyDetails, setCompanyDetails] = useState({});
    const [base64, setBase64] = useState();
    const [socialNetworks, setSocialNetworks] = useState([]);
    const [showColor, setShowColor] = useState(false);
    const [showColorFooter, setShowColorFooter] = useState(false);

    const socialNetworkType = [{
        id: 1,
        name: "Facebook"
    }, {
        id: 2,
        name: "Instagram"
    }, {
        id: 3,
        name: "TripAdvisor"
    }, {
        id: 4,
        name: "Youtube"
    }, {
        id: 5,
        name: "Pinterest"
    }, {
        id: 6,
        name: "Flickr"
    }, {
        id: 7,
        name: "LinkedIn"
    }, {
        id: 8,
        name: "Google"
    }];

    const [companyTitleFont, setCompanyTitleFont] = useState("fontRoboto");

    const [companyDescriptionFont, setCompanyDescriptionFont] = useState("fontRoboto");

    const familyFont = [
        {
            id: "font-family-great-vibes",
            name: "Great Vibes"
        }, {
            id: "font-family-roboto",
            name: "Roboto"
        }, {
            id: "font-family-algerian",
            name: "Algerian"
        }, {
            id: "font-family-arial",
            name: "Arial"
        }, {
            id: "font-family-consolas",
            name: "Consolas"
        }, {
            id: "font-family-calibri",
            name: "Calibri"
        }, {
            id: "font-family-cambria",
            name: "Cambria"
        }, {
            id: "font-family-centaur",
            name: "Centaur"
        }, {
            id: "font-family-sans-serif",
            name: "Sans Serif"
        }];

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const removeImage = () => {
        setBase64(null);
        setValue("image", null);
        setValue("imageUrl", null);
        setCompanyDetails({...companyDetails, imageUrl: null});
    }

    const addSocialNetwork = () => {
        setSocialNetworks([...socialNetworks, {
            url: "",
            type: 1,
        }]);
    }

    const removeSocialNetwork = (index) => {
        const copySocialNetworks = [...socialNetworks];
        copySocialNetworks.splice(index, 1);
        setSocialNetworks(copySocialNetworks);
    }

    const setSocialNetworkType = (index, type) => {
        const copySocialNetworks = [...socialNetworks];
        copySocialNetworks[index].type = parseInt(type);
        setSocialNetworks(copySocialNetworks);
    }

    const setSocialNetworkUrl = (index, url) => {
        const copySocialNetworks = [...socialNetworks];
        copySocialNetworks[index].url = url;
        setSocialNetworks(copySocialNetworks);
    }

    const onSubmitHandle = async (data) => {
        if (data.image?.length > 0) {
            data.imageName = data.image[0].name;
            data.imageBase64 = await BodyService.getBase64(data.image[0]);
            data.imageBase64 = data.imageBase64.split(',')[1];
            data.imageContentType = data.image[0].type;
        }
        if (!showColor) {
            data.textColor = null;
        }
        if (!showColorFooter) {
            data.textColorFooter = null;
        }
        data.companyLink = socialNetworks;
        data.fontFamilyTitle = companyTitleFont;
        data.fontFamilyDescription = companyDescriptionFont;
        delete data.image;
        await onSubmit(data);
    }

    const getCompany = useCallback(async () => {
        try {
            const data = await CompanyService.get();
            setCompanyDetails(data);
            setSocialNetworks(data.companyLink);
            reset(data);
        } catch ({response}) {
            update({...state, error: t("translation:company.error.notFound")})
        }
    }, []);

    useEffect(() => {
        getCompany();
    }, []);

    useEffect(() => {
        if (companyDetails.textColor) {
            setShowColor(true);
        }
        if (companyDetails.textColorFooter) {
            setShowColorFooter(true);
        }
        setCompanyTitleFont(companyDetails.fontFamilyTitle);
        setCompanyDescriptionFont(companyDetails.fontFamilyDescription);

    }, [companyDetails]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:company.fields.infoFields')}
            </p>
            <Row className="mb-md-3">
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.name")}* :</Form.Label>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 40})}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.phone")} :</Form.Label>
                        <Form.Control type="text" {...register("phone")}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.email")} :</Form.Label>
                        <Form.Control type="text" {...register("email")}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-md-3">
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.city")} :</Form.Label>
                        <Form.Control type="text" {...register("city")}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.address")} :</Form.Label>
                        <Form.Control type="text" {...register("address")}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="auto" className="mb-2">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.googleCode")} :</Form.Label>
                        <Form.Control type="text" {...register("googleCode", {minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="auto" className="mb-2 mb-md-0">
                    <Form.Label>{t("translation:company.fields.socialNetworks")}</Form.Label>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="socialNetInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="socialNetInfo" place="right" effect="solid">
                        {t("translation:company.fields.socialNetworkInfo")}
                    </ReactTooltip>
                </Col>
            </Row>
            {socialNetworks?.map((socialNetwork, index) => (
                <Fragment key={index}>
                    <Row md="auto">
                        <Col>
                            <Form.Group>
                                <Form.Control value={socialNetwork.url}
                                              placeholder={t("translation:company.fields.socialNetworkPlaceholder")}
                                              onChange={e => setSocialNetworkUrl(index, e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Select value={socialNetwork.type}
                                         onChange={e => setSocialNetworkType(index, e.target.value)}>
                                {socialNetworkType.map(type => (
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md="auto" className={"mb-3"}>
                            <Button variant="link" size="ls" onClick={() => removeSocialNetwork(index)}>
                                <FontAwesomeIcon icon="fas fa-trash"/>
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            ))}
            <Row>
                <Col md={"auto"}>
                    <FormGroup onClick={addSocialNetwork}>
                        <Form.Label
                            style={{cursor: "pointer"}}>{t("translation:company.fields.addSocialNetwork")}</Form.Label>
                        <Button variant="link" size="lg" className="ms-2">
                            <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.color")}:</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="colorInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="colorInfo" place="right" effect="solid">
                            {t("translation:company.fields.colorInfo")}
                        </ReactTooltip>
                        <Form.Control type="color"{...register("color")}
                                      className="my-2 my-md-0"/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.textColor")}:</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="textColorInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="textColorInfo" place="top" effect="solid">
                            {t("translation:company.fields.textColorInfo")}
                        </ReactTooltip>
                        {showColor &&
                        <Row>
                            <Col md="auto">
                                <Form.Control type="color" className="my-2 my-md-0" {...register("textColor")}/>
                            </Col>
                            <Col>
                                <Button variant="link" size="ls" onClick={() => setShowColor(false)}
                                        className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash"/>
                                </Button>
                            </Col>
                        </Row>
                        }
                        {!showColor &&
                        <Button variant="link" size="lg" className="ms-2"
                                onClick={() => {
                                    setShowColor(true);
                                    setValue("textColor", "#000000");
                                }}>
                            <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                        </Button>
                        }
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.textColorFooter")}:</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="textColorFooterInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="textColorFooterInfo" place="top" effect="solid">
                            {t("translation:company.fields.textColorFooterInfo")}
                        </ReactTooltip>
                        {showColorFooter &&
                        <Row>
                            <Col md="auto">
                                <Form.Control type="color"
                                              className="my-2 my-md-0" {...register("textColorFooter")}/>
                            </Col>
                            <Col>
                                <Button variant="link" size="ls" onClick={() => setShowColorFooter(false)}
                                        className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash"/>
                                </Button>
                            </Col>
                        </Row>
                        }
                        {!showColorFooter &&
                        <Button variant="link" size="lg" className="ms-2"
                                onClick={() => {
                                    setShowColorFooter(true);
                                    setValue("textColorFooter", "#000000");
                                }}>
                            <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                        </Button>
                        }
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            {/*Imagen Logo*/}
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:company.fields.imageLogo")}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="right" effect="solid">
                            {t("translation:company.fields.imageInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image", {required: false})}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="my-2">
                {(companyDetails?.imageUrl || base64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <Image fluid thumbnail src={base64 || companyDetails?.imageUrl}
                           alt={companyDetails?.name}/>
                </Col>
                }
                {(base64 || companyDetails?.imageUrl) &&
                <Col className="align-self-end">
                    <Button variant="link" size="ls" onClick={removeImage}
                            className="border-primary">
                        <FontAwesomeIcon icon="fas fa-trash"/>
                    </Button>
                </Col>
                }
            </Row>
            <Row>
                <Col className={"col-md-auto"}>
                    <FormGroup>
                        <Form.Label>{t('translation:company.fields.companyTitleFont')}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="fontTitleInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="fontTitleInfo" place="right" effect="solid">
                            {t("translation:company.fields.companyTitleFontInfo")}
                        </ReactTooltip>
                        <Form.Select onChange={e => setCompanyTitleFont(e.target.value)}
                                     value={companyTitleFont}>
                            {familyFont.map(font => (
                                <option key={font.id} value={font.id}>{font.name}</option>
                            ))}
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col className={"col-md-auto"}>
                    <FormGroup>
                        <br/>
                        <Form.Label className={`display-4 ${companyTitleFont}`}>{t("translation:company.fields.textFontTest")}</Form.Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className={"col-md-auto"}>
                    <FormGroup>
                        <Form.Label>{t('translation:company.fields.companyDescriptionFont')}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="fontDescriptionInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="fontDescriptionInfo" place="right" effect="solid">
                            {t("translation:company.fields.companyDescriptionFontInfo")}
                        </ReactTooltip>
                        <Form.Select onChange={e => setCompanyDescriptionFont(e.target.value)}
                                     value={companyDescriptionFont}>
                            {familyFont.map(font => (
                                <option key={font.id} value={font.id}>{font.name}</option>
                            ))}
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col className={"col-md-auto"}>
                    <FormGroup>
                        <br/>
                        <Form.Label className={`display-5 ${companyDescriptionFont}`}>{t("translation:company.fields.textFontTest")}</Form.Label>
                    </FormGroup>
                </Col>
            </Row>
            <div className="mt-2">
                {(!formState.isValid) &&
                <p className="infoDisabledButton">
                    {t('translation:company.fields.requiredFields')}
                </p>
                }
                <Button type="submit" disabled={!formState.isValid}>
                    {t("translation:company.fields.buttonUpdate")}
                </Button>
            </div>
        </Form>
    );
}

export default CompanyForm;