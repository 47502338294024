import {Accordion, Alert, Button, Card, Form, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DateTime} from "luxon";

import APP_CONF from "../../scripts/constants";
import OfferForm from "../../components/forms/OfferForm";
import PaginationComponent from "../../components/PaginationComponent";

import BodyService from "../../services/body.service";
import OfferService from "../../services/offer.service";
import DeleteModal from "../../components/DeleteModal";
import AccordionToggle from "../../components/AccordionToggle";
import {Helmet, HelmetData} from "react-helmet-async";

const Offer = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const accordionRef = useRef(null);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [translations, setTranslations] = useState([]);
    const [offers, setOffers] = useState([]);

    const [success, setSuccess] = useState({
        create: false,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false
    });

    const [modalOptions, setModalOptions] = useState({
        show: false,
        id: null
    });

    const handleClose = () => setModalOptions({
        show: false,
        id: null
    });

    const handleShow = (id) => setModalOptions({
        show: true,
        id
    });

    const getOffers = async () => {
        const data = await OfferService.getAll(pagination);
        setOffers(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        });
    }

    const createOffer = async (data) => {
        try {
            await OfferService.create(data);
            setSuccess({...success, create: true});
            accordionRef.current.click();
            getEmptyTranslations();
            getOffers();
        } catch (e) {
            setErrors({...errors, create: true});
            throw e;
        }
    }

    const deleteOffer = async () => {
        try {
            await OfferService.remove(modalOptions.id);
            getOffers();
            setSuccess({...success, delete: true});
            handleClose();
        } catch (e) {
            setErrors({...errors, delete: true});
            handleClose();
        }
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    useEffect(() => {
        getOffers();
    }, [pagination.currentPage]);

    useEffect(() => {
        getEmptyTranslations();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:offer.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('offer.title')}</h1>
            </div>
            <Alert variant="primary" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('offer.success.create')}
            </Alert>
            <Alert variant="primary" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('offer.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('offer.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('offer.error.delete')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('offer.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <OfferForm onSubmit={createOffer} translations={translations}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('offer.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col">{t('translation:general.index')}</th>
                            <th scope="col">{t('translation:general.fields.reference')}</th>
                            <th scope="col">{t('translation:general.fields.nameTranslations')}</th>
                            <th scope="col">{t('translation:offer.list.fields.startDate')}</th>
                            <th scope="col">{t('translation:offer.list.fields.endDate')}</th>
                            <th scope="col">{t('translation:offer.list.fields.active')}</th>
                            <th scope="col">{t('translation:offer.list.fields.creationDate')}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {offers.length > 0 ? offers.map((offer, index) => (
                                <tr key={index}>
                                    <td>{(pagination.currentPage - 1) * pagination.itemsPerPage + index + 1}</td>
                                    <td>{offer.name}</td>
                                    <td>{offer.nameTranslations.map((translation, index) => (
                                            <p key={index}>{translation.language.name}: {translation.value}</p>
                                        )
                                    )}</td>
                                    <td>{DateTime.fromISO(offer.startDate).toFormat("dd/MM/yyyy")}</td>
                                    <td>{DateTime.fromISO(offer.endDate).toFormat("dd/MM/yyyy")}</td>
                                    <td>{offer.active ? t('general.yes') : t('general.no')}</td>
                                    <td>{DateTime.fromISO(offer.creationDate).toFormat("dd/MM/yyyy")}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" onClick={() => handleShow(offer.id)}>
                                            <FontAwesomeIcon icon="trash"/>
                                        </Button>
                                        <Button variant="link" size="sm" className={"ms-2"} to={`/offer/${offer.id}`}
                                                as={Link}>
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={8}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <PaginationComponent pagination={pagination}
                                         setPagination={setPagination}
                                         alwaysShown={offers.length > 0}/>
                </Card.Body>
            </Card>
            <DeleteModal show={modalOptions.show} onDismiss={handleClose} onSubmit={deleteOffer}>
                {t("translation:notificationModel.offer")}
            </DeleteModal>
        </>
    );
};

export default Offer;
