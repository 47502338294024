import {Card, Col, Form, Image, Row} from "react-bootstrap";
import {t} from "i18next";
import {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import CategoryProductService from "../../services/categoryProduct.service";
import {useParams} from "react-router-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAutoAnimate} from "@formkit/auto-animate/react";

const CategoryProductForm = ({category, onSubmit}) => {

    const [animationParent] = useAutoAnimate();

    const {handleSubmit} = useForm({mode: "all"});

    const [products, setProducts] = useState([{}]);

    const params = useParams();

    const onSubmitHandle = (orderedProducts) => {
        orderedProducts.forEach((product, index) => {
                product.productId = product?.id;
                product.categoryId = category?.id;
                product.order = index + 1;
            }
        )
        onSubmit(orderedProducts);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    const orderList = (result) => {
        const {source, destination} = result;
        if (!destination || (source.index === destination.index && source.index === destination.index)) {
            return;
        }
        const orderedProducts = reorder(products, source.index, destination.index);
        setProducts(orderedProducts);
        onSubmitHandle(orderedProducts);
    }

    const getProducts = async (id) => {
        try {
            const data = await CategoryProductService.getAllByCategory(id);
            products.map((product => product.order)
            )
            setProducts(data);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getProducts(params.id);
        }
        fetchData();
    }, [params.id])

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            {products.length > 0 ?
                <Fragment>
                    <Row>
                        <Col className={"my-3"}>
                            <Form.Label>{t("translation:category.creation.fields.products")} {category?.name} :</Form.Label>
                        </Col>
                    </Row>
                    <DragDropContext onDragEnd={(result) => orderList(result)}>
                        <Droppable droppableId={"products"}>
                            {(droppableProvided) =>
                                <div
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                >
                                    <div ref={animationParent}>
                                    {products?.map((product, index) => (
                                            <Draggable key={product.id || index} draggableId={String(product.id)}
                                                       index={index}>
                                                {(draggableProvided) =>
                                                    <Card className="mb-3"
                                                          {...draggableProvided.draggableProps}
                                                          ref={draggableProvided.innerRef}
                                                          {...draggableProvided.dragHandleProps}
                                                    >
                                                        <Card.Body>
                                                            <Row className="mx-0 mx-md-2">
                                                                <Col className="col-6 col-md-4 col-lg-1">
                                                                    <Image fluid thumbnail
                                                                           src={product.frontImageUrl?.url ? product.frontImageUrl?.url : '/img/no_image.jpg'}
                                                                           alt={product.name} className="d-none d-md-flex"/>
                                                                    <Image fluid thumbnail
                                                                           src={product.frontImageUrl?.url ? product.frontImageUrl?.url : '/img/no_image.jpg'}
                                                                           alt={product.name} className="d-flex d-md-none"/>
                                                                </Col>
                                                                <Col className="align-self-center col-6 col-md-8 col-lg-11">
                                                                    <Form.Label className="cursor-grab">
                                                                        <FontAwesomeIcon
                                                                            icon="fa-solid fa-bars"/> {product.name}
                                                                    </Form.Label>
                                                                    <div className="col-5 col-lg-2">
                                                                        <Form.Select value={product.order} onChange={e => orderList(
                                                                            {
                                                                                source: {
                                                                                    index: product.order - 1
                                                                                }, destination: {
                                                                                    index: parseInt(e.target.value) - 1
                                                                                }
                                                                            }
                                                                        )}>
                                                                            {products.map(function (productOrder, i) {
                                                                                return <option key={i+1} value={i+1}>{i + 1}</option>
                                                                            })}

                                                                        </Form.Select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                }
                                            </Draggable>
                                        )
                                    )}
                                    </div>
                                    {droppableProvided.placeholder}
                                </div>}
                        </Droppable>
                    </DragDropContext>
                </Fragment>
                :
                <Row>
                    <Col className={"my-3"}>
                        <Form.Label>{t("translation:category.creation.fields.noProducts")}</Form.Label>
                    </Col>
                </Row>

            }
        </Form>
    );
}

export default CategoryProductForm;
