import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Card, Container} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import LocalService from "../../../services/local.service";
import LocalForm from "../../../components/forms/LocalForm";
import BodyService from "../../../services/body.service";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";
import {v4 as uuidv4} from "uuid";

const LocalDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [local, setLocal] = useState();

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getLocal = useCallback(async (id) => {
        try {
            const data = await LocalService.getById(id);
            let localtmp = data;
            localtmp.scheduleList.forEach((schedule, indexS) => {
                if (schedule.hours.length === 0) {
                    localtmp.scheduleList[indexS].hours = [{startHour: "", endHour: "", scheduleDay: 0, uuid: uuidv4()}]
                }
                schedule.hours.forEach((hour, indexH) => {
                    if (!hour) {
                        localtmp.scheduleList[indexS].hours[indexH] = {startHour: "", endHour: "", scheduleDay: 0, uuid: uuidv4()}
                    }
                })
            });
            setLocal(localtmp);
        } catch (e) {
            update({...state, error: t("translation:local.error.notFound")})
            navigate("/local");
        }
    }, [navigate]);

    const updateLocal = async (data) => {
        try {
            if (data.image?.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            if (data.imageDefault?.length > 0) {
                data.imageDefName = data.imageDefault[0].name;
                data.imageDefBase64 = await BodyService.getBase64(data.imageDefault[0]);
                data.imageDefBase64 = data.imageDefBase64.split(',')[1];
                data.imageDefContentType = data.imageDefault[0].type;
            }
            if (data.cartaPdf?.length > 0) {
                data.cartaPdfName = data.cartaPdf[0].name;
                data.cartaPdfBase64 = await BodyService.getBase64(data.cartaPdf[0]);
                data.cartaPdfBase64 = data.cartaPdfBase64.split(',')[1];
                data.cartaPdfContentType = data.cartaPdf[0].type;
            }
            await LocalService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getLocal(params.id);
        }
        fetchData();
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {local?.name && <title>{local?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/local" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('local.title')}</span>
                </Link>
            </div>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('local.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('local.error.update')}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:local.titleDetails")}</h1>
                </Card.Header>
                <Card.Body>
                    <LocalForm local={local} onSubmit={updateLocal} details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LocalDetails;