import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavbarLink from "./NavbarLink";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import {AppContext} from "../../context/AppContext";

const NavList = () => {

    const {state} = useContext(AppContext);

    const {t} = useTranslation();

    const [showSidebar, setShowSidebar] = useState({
        menu1: false,
        menu2: false,
        menu3: false
    });

    const handleShowmenu1 = () => setShowSidebar({...showSidebar, menu1: !(showSidebar.menu1)});
    const handleShowmenu2 = () => setShowSidebar({...showSidebar, menu2: !(showSidebar.menu2)});
    const handleShowmenu3 = () => setShowSidebar({...showSidebar, menu3: !(showSidebar.menu3)});

    return (
        <>
            <div
                className={`${state.collapsed && "ps-2"} text-white sidebar-heading pt-3 cursor-pointer`}
                onClick={handleShowmenu1}>
                <span>{t('translation:general.sidebar.parametersProducts')}</span>
                <FontAwesomeIcon icon={showSidebar.menu1 ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}
                                 className={"ms-2"}/>
            </div>

            <hr className={"sidebar-divider my-1"}/>

            <div className={`${showSidebar.menu1 && 'd-none'} row`}>
                <NavbarLink url="/product" icon="fas fa-coffee" text={t('translation:general.sidebar.product')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/attribute" icon="fas fa-clipboard-list"
                            text={t('translation:general.sidebar.attribute')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/attribute-type" icon="fas fa-clipboard"
                            text={t('translation:general.sidebar.attributeType')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/category" icon="fas fa-book" text={t('translation:general.sidebar.category')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/family" icon="fa-solid fa-layer-group" text={t('translation:general.sidebar.family')}
                            collapsed={state.collapsed}/>

                {/* Falta implementar la oferta para que tenga alguna función dentro de la web */}
                {/*<NavbarLink url="/offer" icon="fa-solid fa-handshake" text={t('translation:general.sidebar.offer')}*/}
                {/*             collapsed={state.collapsed}/>*/}
            </div>

            <div
                className={`${state.collapsed && "ps-2"} text-white sidebar-heading pt-3 cursor-pointer`}
                onClick={handleShowmenu2}>
                {t('translation:general.sidebar.locations')}
                <FontAwesomeIcon icon={showSidebar.menu2 ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}
                                 className={"ms-2"}/>
            </div>

            <hr className={"sidebar-divider my-1"}/>

            <div className={`${showSidebar.menu2 && 'd-none'} row`}>
                <NavbarLink url="/local" icon="fa-solid fa-building" text={t('translation:general.sidebar.local')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/local-type" icon="fas fa-store-alt" text={t('translation:general.sidebar.localType')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/location" icon="fas fa-map-marker-alt"
                            text={t('translation:general.sidebar.location')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/region" icon="fa-solid fa-earth-europe" text={t('translation:general.sidebar.region')}
                            collapsed={state.collapsed}/>
            </div>

            <div
                className={`${state.collapsed && "ps-2"} text-white sidebar-heading pt-3 cursor-pointer`}
                onClick={handleShowmenu3}>
                {t('translation:general.sidebar.configuration')}
                <FontAwesomeIcon icon={showSidebar.menu3 ? "fa-solid fa-angle-right" : "fa-solid fa-angle-down"}
                                 className={"ms-2"}/>
            </div>

            <hr className={"sidebar-divider my-1"}/>

            <div className={`${showSidebar.menu3 && 'd-none'} row`}>

                <NavbarLink url="/banner" icon="fas fa-image" text={t('translation:general.sidebar.banner')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/language" icon="fa-language" text={t('translation:general.sidebar.language')}
                            collapsed={state.collapsed}/>

                <NavbarLink url="/company" icon="fas fa-briefcase" text={t('translation:general.sidebar.company')}
                            collapsed={state.collapsed}/>
            </div>
        </>
    );
}

export default NavList;