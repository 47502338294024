import {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Card, Col, Form, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import BodyService from "../../services/body.service";
import AttributeService from "../../services/attribute.service";
import AttributeForm from "../../components/forms/AttributeForm";
import AccordionToggle from "../../components/AccordionToggle";
import LanguageService from "../../services/languages.service";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";
import {t} from "i18next";

const Attribute = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const [nameTranslations, setNameTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [nameToSearch, setNameToSearch] = useState('');
    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        update: false,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        duplicate: false,
        linked: false,
        notFound: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const createAttribute = async (data) => {
        try {
            if (data.image.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            data.nameTranslations = nameTranslations;
            data.descriptionTranslations = descriptionTranslations;
            await AttributeService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getEmptyTranslationsName();
            getEmptyTranslationsDescription();
            getAttributes();
        } catch (e) {
            console.error(e);
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else if (e.response.status === 404) {
                setErrors({...errors, notFound: true});
            } else {
                setErrors({...errors, create: true});
                console.error(e);
            }
            throw e;
        }
    };

    const deleteAttribute = async (id) => {
        showModal({
            body: t("translation:notificationModel.attribute"),
            onConfirm: async () => {
                try {
                    await AttributeService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getAttributes();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true});
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    const getAttributes = async () => {
        const data = await AttributeService.getAll(pagination);
        setAttributes(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const handleNameToSearch = (name) => {
        if (name === undefined) {
            setNameToSearch("");
        } else {
            setNameToSearch(name);
        }
    }

    const getLanguages = async () => {
        const data = await LanguageService.getAll();
        setLanguages(data);
    }

    const getEmptyTranslationsName = async () => {
        const data = await BodyService.getEmptyTranslations();
        setNameTranslations(data);
    }

    const getEmptyTranslationsDescription = async () => {
        const data = await BodyService.getEmptyTranslations();
        setDescriptionTranslations(data);
    }

    const handleUpdateAttribute = (attribute, index) => async () => {
        try {
            let tmpObject = {
                active: !attribute.active
            };
            let copyAttributes = attributes;
            copyAttributes[index].active = !copyAttributes[index].active;
            setAttributes(copyAttributes);
            await AttributeService.updateActive(attribute.id, tmpObject);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    const handlePaginationAttribute = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const searchAttributes = async (e) => {
        e.preventDefault();
        const data = await AttributeService.getAllByName(nameToSearch, pagination);
        setAttributes(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    useEffect(() => {
        getAttributes();
    }, [pagination.currentPage, pagination.itemsPerPage])

    useEffect(() => {
        getEmptyTranslationsName();
        getEmptyTranslationsDescription();
        getLanguages();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:attribute.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('attribute.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:attribute.success.create')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:attribute.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('translation:attribute.error.duplicate')}
            </Alert>
            <Alert variant="danger" show={errors.notFound} dismissible
                   onClose={() => setErrors({...errors, notFound: false})}>
                {t('translation:attribute.error.notFound')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:attribute.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:attribute.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:attribute.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h6 className={"m-0 fw-bold text-primary"}>
                                {t('translation:attribute.creation.title')}
                            </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AttributeForm onSubmit={createAttribute}
                                           translations={{nameTranslations, descriptionTranslations}}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <Row>
                        <Col xs={12} md={8} className="mb-2 mb-md-2 mb-lg-0">
                            <h6 className={"m-0 fw-bold text-primary"}>{t('translation:attribute.list.title')}</h6>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Form className="input-group rounded" onSubmit={e => searchAttributes(e)}>
                                <input type="search" className="form-control rounded" placeholder="Buscar"
                                       aria-label="Buscar" onChange={e => handleNameToSearch(e.target.value)}/>
                                <Button type="submit" className="input-group-text border-0">
                                    <FontAwesomeIcon icon="fas fa-search"/>
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col" className={"col-1"}>
                                <span>{t('translation:general.fields.active')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="activeInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="activeInfo" place="top" effect="solid">
                                    {t("translation:general.info.activeAttribute")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.nameTranslations')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="languageInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="languageInfo" place="top" effect="solid">
                                    {t("translation:general.info.language")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.image')}</span>
                            </th>
                            <th scope="col" className="text-center col-2">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {attributes.length > 0 ? attributes.map((attribute, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check type="switch" onChange={handleUpdateAttribute(attribute, index)}
                                                    checked={attribute.active}>
                                        </Form.Check>
                                    </td>
                                    <td>{attribute.name}</td>
                                    <td>
                                        {BodyService.printTranslations(languages, attribute.nameTranslations, index)}
                                    </td>
                                    <td className="col-2">
                                        {attribute.imageUrl &&
                                        <>
                                            <Image fluid thumbnail src={attribute.imageUrl} alt={attribute.name} className="d-none d-md-flex w-50"/>
                                            <Image fluid thumbnail src={attribute.imageUrl} alt={attribute.name} className="d-flex d-md-none"/>
                                        </>
                                        }
                                    </td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/attribute/" + attribute.id}
                                                className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-1 ms-lg-2 border-primary" value={attribute.id}
                                                onClick={() => deleteAttribute(attribute.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('translation:general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={attributes.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationAttribute}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Attribute;
