import {Accordion, Alert, Button, Card, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";

import LocalService from "../../services/local.service";
import LocalForm from "../../components/forms/LocalForm";
import BodyService from "../../services/body.service";
import AccordionToggle from "../../components/AccordionToggle";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { Helmet, HelmetData } from "react-helmet-async";

const Local = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const accordionRef = useRef(null);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [locals, setLocals] = useState([]);

    const [schedule, setSchedule] = useState([]);

    const [success, setSuccess] = useState({
        create: false,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        linked: false
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const getLocals = async () => {
        const data = await LocalService.getAll(pagination);
        setLocals(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        });
    }

    const handlePaginationLocal = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const createLocal = async (data) => {
        try {
            if (data.image?.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            if (data.imageDefault?.length > 0) {
                data.imageDefName = data.imageDefault[0].name;
                data.imageDefBase64 = await BodyService.getBase64(data.imageDefault[0]);
                data.imageDefBase64 = data.imageDefBase64.split(',')[1];
                data.imageDefContentType = data.imageDefault[0].type;
            }
            delete data.imageDefault;
            if (data.cartaPdf?.length > 0) {
                data.pdfName = data.pdf[0].name;
                data.pdfBase64 = await BodyService.getBase64(data.cartaPdf[0]);
                data.pdfBase64 = data.pdf.split(',')[1];
                data.pdfContentType = data.pdf[0].type;
            }
            delete data.cartaPdf;
            await LocalService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getLocals();
        } catch (e) {
            console.error(e);
            setErrors({...errors, create: true});
            throw e;
        }
    }

    const deleteLocal = async (id) => {
        showModal({
            body: t("translation:notificationModel.local"),
            onConfirm: async () => {
                try {
                    await LocalService.remove(id);
                    setSuccess({...success, delete: true});
                    getLocals();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true})
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    useEffect(() => {
        getLocals();
    }, [pagination.currentPage, pagination.itemsPerPage]);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:local.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('translation:local.title')}</h1>
            </div>
            <Alert variant="primary" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:local.success.create')}
            </Alert>
            <Alert variant="primary" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:local.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:local.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:local.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:local.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6
                            className={"m-0 fw-bold text-primary"}>{t('translation:local.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <LocalForm onSubmit={createLocal} details={false}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('translation:local.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col" className="col-1">
                                <span>{t('translation:general.index')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:local.list.fields.name')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="nameInfoLocal"
                                                 className="infoDialog"/>
                                <ReactTooltip id="nameInfoLocal" place="top" effect="solid">
                                    {t("translation:general.info.name")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:local.list.fields.phone')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:local.list.fields.dir1')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:local.list.fields.dir2')}</span>
                            </th>
                            <th scope="col">{t('translation:local.list.fields.postalCode')}</th>
                            <th scope="col">{t('translation:local.list.fields.province')}</th>
                            <th scope="col" className="text-center col-2">{t('translation:general.fields.actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {locals.length > 0 ? locals.map((local, index) => (
                                <tr key={index}>
                                    <td>{(pagination.currentPage - 1) * pagination.itemsPerPage + index + 1}</td>
                                    <td>{local.name}</td>
                                    <td>{local.phone}</td>
                                    <td>{local.dir1}</td>
                                    <td>{local.dir2}</td>
                                    <td>{local.postalCode}</td>
                                    <td>{local.province}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" to={`/local/${local.id}`}
                                                as={Link} className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary"
                                                onClick={() => deleteLocal(local.id)}>
                                            <FontAwesomeIcon icon="trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={8}>{t('translation:general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={locals.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationLocal}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Local;
