import {t} from "i18next";
import {Alert, Button, Col, Form, FormGroup, Image, Row} from "react-bootstrap";
import BodyService from "../../services/body.service";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import AttributeTypeService from "../../services/attributeType.service";
import TranslationInput from "./fields/TranslationInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const AttributeForm = ({attribute, translations, onSubmit, details}) => {

    const [base64, setBase64] = useState();
    const [attributeDetails, setAttributeDetails] = useState({});
    const [attributeType, setAttributeType] = useState([]);
    const [nameTranslations, setNameTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [attributeTypeSelected, setAttributeTypeSelected] = useState(details);
    const [showAlerts, setShowAlerts] = useState(false);

    const {register, handleSubmit, formState, reset, setValue} = useForm({mode: "all"});

    const setNameTranslationValue = (index, value) => {
        setNameTranslations(translations.nameTranslations.map((nameTranslation, i) => {
            if (i === index) {
                nameTranslation.value = value;
            }
            return nameTranslation;
        }));
    }

    const setDescriptionTranslationValue = (index, value) => {
        setDescriptionTranslations(translations.descriptionTranslations.map((descriptionTranslation, i) => {
            if (i === index) {

                descriptionTranslation.value = value;
            }
            return descriptionTranslation;
        }));
    }

    const getAttributeType = async () => {
        const data = await AttributeTypeService.getAll();
        setAttributeType(data);
    }

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const removeImage = () => {
        setBase64(null);
        setValue('image', null);
        setValue('imageUrl', null);
        if (attribute) {
            setAttributeDetails({...attribute, imageUrl: null});
        }
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        data.nameTranslations = nameTranslations;
        data.descriptionTranslations = descriptionTranslations;
        await onSubmit(data);
        if (!details) {
            setAttributeTypeSelected(false);
            removeImage();
            reset();
        }
    }

    useEffect(() => {
        if (!details || (details && attribute)) {
            getAttributeType();
            reset({
                ...attribute
            });
            setAttributeDetails(attribute);
            setShowAlerts(true);
        }
    }, [attribute])

    useEffect(() => {
        if (!details || (details && attribute !== {})) {
            setNameTranslations(translations.nameTranslations);
            setDescriptionTranslations(translations.descriptionTranslations);
        }
    }, [translations]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:product.creation.fields.infoFields')}
            </p>
            <Row className="mb-4">
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('translation:attribute.creation.fields.reference')}* :</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="referenceInfo" place="top" effect="solid">
                            {t("translation:attribute.creation.fields.referenceInfo")}
                        </ReactTooltip>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('translation:attribute.creation.fields.attributeType')}*</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="attributeTypeinfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="attributeTypeinfo" place="top" effect="solid">
                            {t("translation:attribute.creation.fields.attributeTypeInfo")}
                        </ReactTooltip>
                        <Form.Select {...register("attributeTypeId")} onChange={() => setAttributeTypeSelected(true)}>
                            {!attributeTypeSelected && <option></option>}
                            {attributeType.map(attributeType => (
                                <option key={attributeType.id} value={attributeType.id}>{attributeType.name}</option>
                            ))}
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                        <Form.Check type="switch"
                                    defaultChecked={!details ? false : attribute?.active} {...register("active")}/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                             className="infoDialog m-0"/>
            <ReactTooltip id="translationInfo" place="right" effect="solid">
                {t("translation:attribute.creation.fields.translationInfo")}
            </ReactTooltip>
            {translations.nameTranslations?.length > 0 ?
                <div>
                    <Row>
                        <Col className="mb-2">
                            <label>{t('translation:general.fields.nameTranslations')} :</label>
                        </Col>
                        <Row className="mb-3">
                            <TranslationInput translations={nameTranslations || []} onChange={setNameTranslationValue}/>
                        </Row>
                    </Row>
                    <Row>
                        <Col className="mb-2">
                            <label>{t('translation:attribute.list.description')} :</label>
                        </Col>
                        <Row className="mb-3">
                            <TranslationInput translations={descriptionTranslations || []}
                                              onChange={setDescriptionTranslationValue}/>
                        </Row>
                    </Row>
                </div>
                :
                <Alert variant="danger" className="mt-2" show={showAlerts}>
                    {t('translation:general.emptyTranslations')}
                </Alert>
            }
            <hr/>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label className="mb-3">{t('translation:general.fields.image')}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="right" effect="solid">
                            {t("translation:attribute.creation.fields.imageInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image")}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-3">
                {(attributeDetails?.imageUrl || base64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <Image fluid thumbnail src={base64 || attributeDetails?.imageUrl}
                           alt={attribute?.name}/>
                </Col>
                }
                {(base64 || attributeDetails?.imageUrl) &&
                <Col className="align-self-end">
                    <Button variant="link" size="ls" onClick={removeImage}
                            className="border-primary">
                        <FontAwesomeIcon icon="fas fa-trash"/>
                    </Button>
                </Col>
                }
            </Row>
            <div className="mt-4">
                {(!formState.isValid || !attributeTypeSelected) &&
                <p className="infoDisabledButton">
                    {t('translation:attribute.creation.fields.requiredFields')}
                </p>
                }
                <Button type="submit" disabled={!formState.isValid || !attributeTypeSelected}>
                    {!details ? t('translation:attribute.creation.buttonCreate') : t('translation:attribute.creation.buttonUpdate')}
                </Button>
            </div>
        </Form>
    );
}

export default AttributeForm;