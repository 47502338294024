import api from './api';

const getAll = (pagination, filter = "") => {
    return api.get("product", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage,
        filter
    }).then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`product/${id}`).then((response) => {
        return response.data;
    })
}

const getAllByCategoryAndName = (categoryId, productName, pagination) => {
    if (productName === null) {
        getAll(pagination);
    } else {
        return api.get("product/search/", {
            productName,
            categoryId,
            pageNum: pagination.currentPage - 1,
            pageLength: pagination.itemsPerPage
        }).then((response) => {
            return response.data;
        })
    }
}

const create = (product) => {
    return api.post("product", product).then((response) => {
        return response.data;
    });
}

const update = (id, product) => {
    return api.put(`product/${id}`, product).then((response) => {
        return response.data;
    })
}

const updateActive = (id, product) => {
    return api.put(`product/${id}/active`, product).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`product/${id}`).then((response) => {
        return response.data;
    })
}

const cutImage = (image) => {
    return api.post("product/clip-image", image, {responseType: "arrayBuffer"}).then((response) => {
        return response.data;
    })
}

const getImage = (imageId) => {
    return api.get(`product/clip-image/${imageId}`).then((response) => {
        return response.data;
    })
}

const ProductService = {
    getAll,
    getById,
    getAllByCategoryAndName,
    create,
    update,
    updateActive,
    deleteById,
    cutImage,
    getImage
}

export default ProductService;