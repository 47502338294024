import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Button, Card, Container, Form} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BannerForm from "../../../components/forms/BannerForm";
import BodyService from "../../../services/body.service";
import BannerService from "../../../services/banner.service";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const BannerDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [banner, setBanner] = useState({});
    const [translations, setTranslations] = useState([]);

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getBanner = useCallback(async (id) => {
        try {
            const data = await BannerService.getById(id);
            setBanner(data);
            setTranslations(data.descriptionTranslations);
        } catch (e) {
            update({...state, error: t("translation:banner.error.notFound")})
            navigate("/banner");
        }
    }, [navigate]);

    const updateBanner = async (data) => {
        data.descriptionTranslations = translations;
        try {
            if (data.image?.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            await BannerService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    useEffect(async () => {
        await getBanner(params.id);
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {banner?.name && <title>{banner?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/banner" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('banner.title')}</span>
                </Link>
            </div>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('banner.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('banner.error.update')}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:banner.title")}</h1>
                </Card.Header>
                <Card.Body>
                    <BannerForm banner={banner} translations={translations} onSubmit={updateBanner} details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default BannerDetails;