import {Alert, Card, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import CategoryService from "../../../services/category.service";
import {AppContext} from "../../../context/AppContext";
import CategoryProductForm from "../../../components/forms/CategoryProductForm";
import CategoryProductService from "../../../services/categoryProduct.service";
import {Helmet, HelmetData} from "react-helmet-async";

const CategoryProduct = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [category, setCategory] = useState();

    const {state, update} = useContext(AppContext);

    const [errors, setErrors] = useState({
        update: false,
    });

    const getCategory = async (id) => {
        try {
            const data = await CategoryService.getById(id);
            setCategory(data);
        } catch (e) {
            update({...state, error: t("translation:category.error.notFound")})
            navigate("/category");
        }
    }

    const updateCategoryProduct = async (data) => {
        try {
            await CategoryProductService.update(params.id, data);
        } catch (e) {
            console.error(e);
            setErrors({...errors, update: true});
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getCategory(params.id);
        }
        fetchData();
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {category?.name && <title>{t("translation:categoryProduct.title")} ${category?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/category" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('category.title')}</span>
                </Link>
            </div>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('translation:categoryProduct.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:category.titleDetails")}</h1>
                </Card.Header>
                <Card.Body>
                    <CategoryProductForm category={category}
                                         onSubmit={updateCategoryProduct}/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default CategoryProduct;

