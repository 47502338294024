import {Col, Form, FormGroup} from "react-bootstrap";
import {useEffect, useRef} from "react";
import autosize from "autosize/dist/autosize";
import RichTextArea from "../RichTextarea";

const TranslationDescriptionInput = ({translations, onChange}) => {

    const translationsRefs = useRef([]);

    useEffect(() => {
        translationsRefs.current = translationsRefs.current.slice(0, translations.length);
    }, [translations]);

    useEffect(() => {
        translationsRefs.current.forEach(translationRef => {
            autosize(translationRef);
        })
    }, [translationsRefs.current]);

    const handleChange = (index, text) => {
        onChange(index, text)
    }

    return (
        translations?.map((translation, index) => (
            <Col md="auto" key={index}>
                <FormGroup>
                    <Form.Label>{translation.language.name}</Form.Label>
                    <RichTextArea text={translation?.value || ""}
                                  block="description"
                                  detail={true}
                                  onChange={(_,text) => handleChange(index,text)}
                                  height={140} />

                </FormGroup>
            </Col>
        ))
    );
}

export default TranslationDescriptionInput;