import {useContext, useEffect, useState} from "react";
import {Alert, Card, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";


import AttributeService from "../../../services/attribute.service";
import AttributeForm from "../../../components/forms/AttributeForm";
import BodyService from "../../../services/body.service";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const AttributeDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const {reset} = useForm({mode: "onChange"});

    const [nameTranslations, setNameTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [attribute, setAttribute] = useState()

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getAttribute = async (id) => {
        try {
            const data = await AttributeService.getById(id);
            setAttribute(data);
            reset(data);
            setNameTranslations(data.nameTranslations);
            setDescriptionTranslations(data.descriptionTranslations);
        } catch (e) {
            update({...state, error: t("translation:attribute.error.notFound")})
            navigate("/attribute");
        }
    }

    const updateAttribute = async (data) => {
        setSuccess({update: false});
        setErrors({update: false});
        try {
            if (data.image?.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            data.nameTranslations = nameTranslations;
            data.descriptionTranslations = descriptionTranslations;
            await AttributeService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
            console.error(e);
        }
        await getAttribute(params.id);
    };

    useEffect(() => {
        getAttribute(params.id);
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {attribute?.name && <title>{attribute?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/attribute" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('attribute.title')}</span>
                </Link>
            </div>
            <Alert variant="success" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('attribute.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('attribute.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t('translation:attribute.title')}</h1>
                </Card.Header>
                <Card.Body>
                    <AttributeForm attribute={attribute}
                                   onSubmit={updateAttribute}
                                   translations={{
                                       nameTranslations: attribute?.nameTranslations,
                                       descriptionTranslations: attribute?.descriptionTranslations
                                   }}
                                   details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default AttributeDetails;
