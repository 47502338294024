import api from "./api";

const getAll = (pagination) => {
    return api.get("location", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("location/list").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`location/${id}`).then((response) => {
        return response.data;
    })
}

const create = (locationDTO) => {
    return api.post("location", locationDTO).then((response) => {
        return response.data;
    })
}

const update = (id, location) => {
    return api.put(`location/${id}`, location).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`location/${id}`).then((response) => {
        return response.data;
    })
}

const LocationService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    deleteById
}

export default LocationService;