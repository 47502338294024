import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Card, Container} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../../context/AppContext";
import LocalTypeService from "../../../../services/localType.service";
import LocalTypeForm from "../../../../components/forms/LocalTypeForm";
import {Helmet, HelmetData} from "react-helmet-async";

const LocalTypeDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [localType, setLocalType] = useState({});

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getLocalType = useCallback(async (id) => {
        try {
            const data = await LocalTypeService.getById(id);
            setLocalType(data);
        } catch (e) {
            update({...state, error: t("translation:attributeType.error.notFound")})
            navigate("/local-type");
        }
    }, [navigate]);

    const updateLocalType = async (data) => {
        try {
            await LocalTypeService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    useEffect(async () => {
        await getLocalType(params.id);
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {localType?.name && <title>{localType?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/local-type" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t("translation:localType.title")}</span>
                </Link>
            </div>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t("translation:localType.success.update")}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t("translation:localType.error.update")}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:localType.title")}</h1>
                </Card.Header>
                <Card.Body>
                    <LocalTypeForm localType={localType} onSubmit={updateLocalType} details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LocalTypeDetails;