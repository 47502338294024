import {Alert, Card, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import FamilyService from "../../../services/family.service";
import FamilyCategoryService from "../../../services/familyCategory.service";
import FamilyCategoryForm from "../../../components/forms/FamilyCategoryForm";
import {Helmet, HelmetData} from "react-helmet-async";

const CategoryProduct = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [family, setFamily] = useState();

    const {state, update} = useContext(AppContext);

    const [errors, setErrors] = useState({
        update: false,
    });

    const getFamily = async (id) => {
        try {
            const data = await FamilyService.getById(id);
            setFamily(data);
        } catch (e) {
            update({...state, error: t("translation:family.error.notFound")})
            navigate("/family");
        }
    }

    const updateFamilyCategory = async (data) => {
        try {
            await FamilyCategoryService.update(params.id, data);
        } catch (e) {
            console.error(e);
            setErrors({...errors, update: true});
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getFamily(params.id);
        }
        fetchData();
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {family?.name && <title>{t("translation:familyCategory.title")} ${family?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/family" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('translation:family.title')}</span>
                </Link>
            </div>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('translation:familyCategory.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:family.titleDetails")}</h1>
                </Card.Header>
                <Card.Body>
                    <FamilyCategoryForm family={family}
                                         onSubmit={updateFamilyCategory}/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default CategoryProduct;

