import {Card, Col, Form, Image, Row} from "react-bootstrap";
import {t} from "i18next";
import {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import FamilyCategoryService from "../../services/familyCategory.service";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FamilyCategoryForm = ({family, onSubmit}) => {

    const {handleSubmit} = useForm({mode: "all"});

    const [categories, setCategories] = useState([{}]);

    const params = useParams();

    const onSubmitHandle = (orderedCategories) => {
        orderedCategories.forEach((category, index) => {
                category.categoryId = category.id;
                category.familyId = family.id;
                category.order = index + 1;
            }
        )
        onSubmit(orderedCategories);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    const orderList = (result) => {
        const {source, destination} = result;
        if (!destination || (source.index === destination.index && source.index === destination.index)) {
            return;
        }
        const orderedCategories = reorder(categories, source.index, destination.index);
        setCategories(orderedCategories);
        onSubmitHandle(orderedCategories);
    }

    const getCategories = async (id) => {
        try {
            const data = await FamilyCategoryService.getAllByFamily(id);
            categories.map((category => category.order))
            setCategories(data);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getCategories(params.id);
        }
        fetchData();
    }, [params.id])

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            {categories.length > 0 ?
                <Fragment>
                    <Row>
                        <Col className={"ms-3 my-3"}>
                            <Form.Label
                                className="h4">{t("translation:family.creation.fields.categories")} {family?.name} :</Form.Label>
                        </Col>
                    </Row>
                    <DragDropContext onDragEnd={(result) => orderList(result)}>
                        <Droppable droppableId={"categories"}>
                            {(droppableProvided) =>
                                <div
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                >
                                    {categories?.map((category, index) => (
                                        <Draggable key={category.id || index} draggableId={String(category.id)}
                                                   index={index}>
                                            {(draggableProvided) =>
                                                <Card className="mb-3"
                                                      {...draggableProvided.draggableProps}
                                                      ref={draggableProvided.innerRef}
                                                      {...draggableProvided.dragHandleProps}
                                                >
                                                    <Card.Body>
                                                        <Row className="mx-0 mx-md-2">
                                                            <Col className="col-6 col-md-4 col-lg-1">
                                                                <Image fluid thumbnail
                                                                       src={category?.imageUrl ? category?.imageUrl : '/img/no_image.jpg'}
                                                                       alt={category.name} className="d-none d-md-flex"/>
                                                                <Image fluid thumbnail
                                                                       src={category?.imageUrl ? category?.imageUrl : '/img/no_image.jpg'}
                                                                       alt={category.name} className="d-flex d-md-none"/>
                                                            </Col>
                                                            <Col className="align-self-center col-6 col-md-8 col-lg-11">
                                                                <Form.Label className="cursor-grab">
                                                                    <FontAwesomeIcon
                                                                        icon="fa-solid fa-bars"/> {category.name}
                                                                </Form.Label>
                                                                <div className="col-5 col-lg-2">
                                                                    <Form.Select value={category.order} onChange={e => orderList(
                                                                        {
                                                                            source: {
                                                                                index: category.order - 1
                                                                            }, destination: {
                                                                                index: parseInt(e.target.value) - 1
                                                                            }
                                                                        }
                                                                    )}>
                                                                        {categories.map(function (categoryOrder, i) {
                                                                            return <option key={i+1} value={i+1}>{i + 1}</option>
                                                                        })}

                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            }
                                        </Draggable>
                                        )
                                    )}
                                    {droppableProvided.placeholder}
                                </div>}
                        </Droppable>
                    </DragDropContext>
                </Fragment>
                :
                <Row>
                    <Col className={"my-3"}>
                        <Form.Label>{t("translation:family.creation.fields.noCategories")}</Form.Label>
                    </Col>
                </Row>

            }
        </Form>
    );
}

export default FamilyCategoryForm;
