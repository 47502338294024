import {Alert, Button, Col, Form, FormGroup, Image, Row} from "react-bootstrap";
import {t} from "i18next";
import {useEffect, useState} from "react";
import BodyService from "../../services/body.service";
import Select from "react-select";
import {useForm} from "react-hook-form";
import FamilyService from "../../services/family.service";
import TranslationInput from "./fields/TranslationInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const CategoryForm = ({category, translations, onSubmit, details}) => {

    const {register, handleSubmit, formState, reset, getValues, setValue} = useForm({mode: "all"});

    const [categoryDetails, setCategoryDetails] = useState({});
    const [families, setFamilies] = useState([]);
    const [nameTranslations, setNameTranslations] = useState([]);
    const [familiesSelected, setFamiliesSelected] = useState([]);
    const [base64, setBase64] = useState();
    const [showAlerts, setShowAlerts] = useState(false);

    const getFamilies = async () => {
        const data = await FamilyService.getAllList();
        setFamilies(data);
    }

    const setTranslationValue = (index, value) => {
        const copyNameTranslations = [...nameTranslations];
        copyNameTranslations[index].value = value;
        setNameTranslations(copyNameTranslations);
    }

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const removeImage = () => {
        setBase64(null);
        setValue("image", null);
        setValue("imageUrl", null);
        if (category) {
            setCategoryDetails({...category, imageUrl: null});
        }
    }

    const setFormFamilies = (family) => {
        setValue(`families`, family);
        setFamiliesSelected(getValues("families"));
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid || !familiesSelected.length > 0) return;
        data.nameTranslations = nameTranslations;

        if (data.image[0]?.name) {
            data.imageName = data.image[0].name;
            data.imageBase64 = await BodyService.getBase64(data.image[0]);
            data.imageBase64 = data.imageBase64.split(',')[1];
            data.imageContentType = data.image[0].type;
        }
        delete data.image;

        await onSubmit(data);
        if (!details) {
            setFamiliesSelected([]);
            removeImage();
            reset();
        }
    }

    useEffect(() => {
        getFamilies();
        if (details) {
            reset({
                ...category
            });
            setCategoryDetails(category);
            setFamiliesSelected(category?.families || []);
            setShowAlerts(true);
        }
    }, [category]);

    useEffect(() => {
        if (!details || (details && category !== {})) {
            setNameTranslations(translations);
        }
    }, [translations]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:category.creation.fields.infoFields')}
            </p>
            <Row className={"mb-3"}>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t("translation:general.fields.reference")}* :</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="referenceInfo" place="top" effect="solid">
                            {t("translation:attribute.creation.fields.referenceInfo")}
                        </ReactTooltip>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                        <Form.Check className="mt-1" type="switch"
                                    defaultChecked={!details ? false : category?.active} {...register("active")}/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                             className="infoDialog m-0"/>
            <ReactTooltip id="translationInfo" place="right" effect="solid">
                {t("translation:category.creation.fields.translationInfo")}
            </ReactTooltip>
            {translations?.length > 0 ?
                <Row className="mb-3">
                    <Col>
                        <label className="mb-2">{t("translation:general.fields.nameTranslations")} :</label>
                        <Row>
                            <TranslationInput translations={nameTranslations} onChange={setTranslationValue}/>
                        </Row>
                    </Col>
                </Row>
                :
                <Alert variant="danger" className="mt-2" show={showAlerts}>
                    {t('translation:general.emptyTranslations')}
                </Alert>
            }
            <hr/>
            <Row className={"mb-3"}>
                <Col md="6" lg="4">
                    <Form.Label>{t("translation:category.creation.fields.family")}* :</Form.Label>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="familyInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="familyInfo" place="right" effect="solid">
                        {t("translation:category.creation.fields.familyInfo")}
                    </ReactTooltip>
                    {families.length > 0 &&
                    <Select options={families}
                            isMulti={true}
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            placeholder=""
                            value={familiesSelected}
                            noOptionsMessage={() => t("general.empty")}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            onChange={setFormFamilies}/>
                    }
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label className="mb-2">{t("translation:general.fields.image")}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="right" effect="solid">
                            {t("translation:category.creation.fields.imageInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image")}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-3">
                {(categoryDetails?.imageUrl || base64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <Image fluid thumbnail src={base64 || categoryDetails?.imageUrl}
                           alt={category?.name}/>
                </Col>
                }
                {(base64 || categoryDetails?.imageUrl) && (
                    <Col className="align-self-end">
                        <Button variant="link" size="ls" onClick={removeImage}
                                className="border-primary">
                            <FontAwesomeIcon icon="fas fa-trash"/>
                        </Button>
                    </Col>
                )}
            </Row>
            <div className="mt-4">
                {(!formState.isValid || !familiesSelected.length > 0) &&
                <p className="infoDisabledButton">
                    {t('translation:category.creation.fields.requiredFields')}
                </p>
                }
                <Button type="submit" disabled={!formState.isValid || !familiesSelected.length > 0}>
                    {!details ? t("translation:category.creation.buttonCreate") : t("translation:category.creation.buttonUpdate")}
                </Button>
            </div>

        </Form>
    );
}

export default CategoryForm;
