import {Accordion, Alert, Button, Card, Col, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../scripts/constants";
import PaginationComponent from "../../components/PaginationComponent";
import FamilyForm from "../../components/forms/FamilyForm";
import FamilyService from "../../services/family.service";
import BodyService from "../../services/body.service";
import AccordionToggle from "../../components/AccordionToggle";
import LanguageService from "../../services/languages.service";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";

const Family = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const [families, setFamilies] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [languages, setLanguages] = useState([]);
    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        linked: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const createFamily = async (data) => {
        try {
            await FamilyService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getEmptyTranslations();
            getFamilies();
        } catch (e) {
            console.error(e);
            setErrors({...errors, create: true});
            throw e;
        }
    };

    const deleteFamily = async (id) => {
        showModal({
            body: t("translation:notificationModel.family"),
            onConfirm: async () => {
                try {
                    await FamilyService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getFamilies();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true})
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    const getFamilies = async () => {
        const data = await FamilyService.getAll(pagination);
        setFamilies(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const handlePaginationFamily = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const getLanguages = async () => {
        const data = await LanguageService.getAll();
        setLanguages(data);
    }

    const getEmptyTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    useEffect(() => {
        getFamilies();
    }, [pagination.currentPage, pagination.itemsPerPage]);

    useEffect(() => {
        getEmptyTranslations();
        getLanguages();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:family.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('translation:family.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:family.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:family.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:family.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:family.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:family.error.linked')}
            </Alert>

            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h6 className={"m-0 fw-bold text-primary"}>{t('translation:family.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <FamilyForm translations={translations} onSubmit={createFamily}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('translation:family.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col" className={"col-1"}>
                                <span>{t('translation:general.index')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo2"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo2" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.nameTranslations')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="languageInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="languageInfo" place="top" effect="solid">
                                    {t("translation:general.info.language")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.image')}</span>
                            </th>
                            <th scope="col" className={"text-center col-2"}>
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                            {/*<th scope="col">{t('translation:family.list.fields.parentFamily')}</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {families.length > 0 ? families.map((family, index) => (
                                <tr key={index}>
                                    <td>{(index + (pagination.currentPage - 1) * pagination.itemsPerPage) + 1}</td>
                                    <td>{family.name}</td>
                                    <td>
                                        {BodyService.printTranslations(languages, family.nameTranslations, index)}
                                    </td>
                                    {/*<td>*/}
                                    {/*    {family.parentFamilyName}*/}
                                    {/*</td>*/}
                                    <td className="w-25">
                                        <Image fluid thumbnail
                                               src={family.imageUrl ? family.imageUrl : '/img/no_image.jpg'}
                                               alt={family.name} className="d-none d-md-flex w-50"/>
                                        <Image fluid thumbnail
                                               src={family.imageUrl ? family.imageUrl : '/img/no_image.jpg'}
                                               alt={family.name} className="d-flex d-md-none"/>
                                    </td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/family/" + family.id}
                                                className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary" as={Link}
                                                to={"/family-category/" + family.id}>
                                            <FontAwesomeIcon icon="fas fa-sort-amount-down"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary"
                                                value={family.id} onClick={() => deleteFamily(family.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('translation:general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={families.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationFamily}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Family;
