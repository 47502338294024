import {t} from "i18next";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

const LocalTypeForm = ({localType, onSubmit, details}) => {

    const {register, handleSubmit, formState, reset} = useForm({mode: "all", defaultValues: {}});

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        await onSubmit(data);
        if (!details) {
            reset();
        }
    }

    useEffect(() => {
        reset({
            ...localType
        });
    }, [localType])

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:localType.creation.fields.infoFields')}
            </p>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Form.Label>{t("translation:localType.creation.fields.name")}* :</Form.Label>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
            </Row>
            <Button type="submit" className={"mt-4"} disabled={!formState.isValid}>
                {!details ? t("translation:localType.creation.buttonCreate") : t("translation:localType.creation.buttonUpdate")}
            </Button>
        </Form>
    );
}

export default LocalTypeForm;