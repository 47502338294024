import {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Card, Col, Form, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../scripts/constants";
import LocationForm from "../../components/forms/LocationForm";
import PaginationComponent from "../../components/PaginationComponent";
import LocationService from "../../services/location.service";
import AccordionToggle from "../../components/AccordionToggle";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";


const Location = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
        update: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        update: false,
        duplicate: false,
        linked: false
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [location, setLocation] = useState([]);

    const getLocations = async () => {

        const data = await LocationService.getAll(pagination);
        setLocation(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const handlePaginationLocation = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const createLocation = async (locationDTO) => {
        try {
            await LocationService.create(locationDTO);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getLocations();
        } catch (e) {
            console.error(e);
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, create: true});
            }
            throw e;
        }
    };

    const deleteLocation = async (id) => {
        showModal({
            body: t("translation:notificationModel.location"),
            onConfirm: async () => {
                try {
                    await LocationService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getLocations();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true})
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    useEffect(() => {
        getLocations();
    }, [pagination.currentPage, pagination.itemsPerPage])

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:location.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('location.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('location.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('location.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('location.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('location.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('location.error.duplicate')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:location.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('location.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <LocationForm onSubmit={createLocation}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('location.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col">
                                <span>{t('translation:general.index')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col" className="text-center col-2">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {location.length > 0 ? location.map((location, index) => (
                                <tr key={index}>
                                    <td>{(index + (pagination.currentPage - 1) * pagination.itemsPerPage) + 1}</td>
                                    <td>{location.name}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/location/" + location.id} className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-sm-0 ms-md-2 border-primary"
                                                value={location.id} onClick={() => deleteLocation(location.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={location.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationLocation}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Location;
