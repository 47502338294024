import {useState} from "react";
import {Alert, Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCopyright} from "@fortawesome/free-regular-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core";
import AuthService from "../../services/auth.service";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Login = () => {

    const {t} = useTranslation();

    library.add(faCopyright);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        login: false,
        server: false
    });

    const navigate = useNavigate();

    const loginSubmit = (e) => {
        e.preventDefault();

        setErrors({
            login: false,
            server: false
        });

        AuthService.login(email, password).then(
            () => navigate("/"),
            (error) => {
                if (error.response.data.errorCode === 12011) {
                    setErrors({...errors, login: true});
                } else {
                    setErrors({...errors, server: true});
                }
            }
        );
    }

    return (
        <Container>
            <Row className={'justify-content-center'}>
                <Col xl={10} lg={12} md={9}>
                    <div className={"card shadow-lg my-5"}>
                        <Row>
                            <Col lg={6} className={"d-none d-lg-block"}>
                                <Image src={"/img/login/img-login.jpg"} className={"rounded-start"} fluid={true}/>
                            </Col>
                            <Col lg={6} className={"align-self-center"}>
                                <div className={"card-body p-0"}>
                                    <div className={"p-5"}>
                                        <div className={"text-center"}>
                                            <h1 className={"h4 mb-4"}>¡Bienvenido!</h1>
                                        </div>
                                        <Form className={"mb-3"} name={"loginForm"} onSubmit={loginSubmit}>
                                            <div className={"mb-3"}>
                                                <input type={"email"} className={"form-control"}
                                                       value={email}
                                                       onChange={(e) => setEmail(e.target.value)}
                                                       placeholder={"Dirección de email"} required={true}/>
                                            </div>
                                            <div className={"mb-3"}>
                                                <input type={"password"} className={"form-control"}
                                                       value={password} onChange={(e) => setPassword(e.target.value)}
                                                       placeholder={"Contraseña"} required={true}/>
                                            </div>
                                            <div className={"d-grid gap-1"}>
                                                <Button type={"submit"}
                                                        className={"btn btn-primary btn-block"}>{t('general.login')}</Button>
                                            </div>
                                        </Form>
                                        {errors.login && (
                                            <Alert variant="danger"
                                                   onClose={() => setErrors({...errors, login: false})} dismissible>
                                                Lo siento, este nombre de usuario y contraseña no son correctos, vuelva
                                                a intentarlo.
                                            </Alert>
                                        )}
                                        {errors.server && (
                                            <Alert variant="danger"
                                                   onClose={() => setErrors({...errors, server: false})} dismissible>
                                                Ha habido un problema en el servidor, vuelva a intentarlo dentro de unos
                                                minutos.
                                            </Alert>
                                        )}
                                        <hr/>
                                        <div className={"text-center"}>
                                            <p className={"small"}><FontAwesomeIcon icon={['far', 'copyright']}/> Proa
                                                Data {new Date().getFullYear()}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
