import api from './api';
import {Fragment} from "react";
import ReactTooltip from "react-tooltip";

const getEmptyTranslations = () => {
    return api.get('translations/empty').then((response) => {
        return response.data;
    });
}

const getBase64 = (file) => {
    return new Promise(function (resolve) {
        var reader = new FileReader();
        reader.onloadend = function () {
            resolve(reader.result)
        }
        reader.readAsDataURL(file);
    })
}

const checkUniqueArray = (array) => {
    return new Set(array).size === array.length
}

const printTranslations = (languages, translations, index) => {
    return languages.map(language => {
        let isActive = false;
        let valueTranslation;
        translations.forEach(translation => {
            if (translation.language.id === language.languageId && language.active && translation.value != null) {
                isActive = true;
                valueTranslation = translation.value;
            }
        });
        return (
            <Fragment key={language.languageId}>
                {isActive ?
                    <>
                        <img className={'img-flags me-1'}
                             src={language.imageUrl}
                             data-tip={language.languageName + " : " + valueTranslation}/>
                        <ReactTooltip className={'bg-black'} place={"top"}/>
                    </>
                    :
                    <img className={'img-flags-deactivated me-1'}
                         src={language.imageUrl}
                    />
                }
            </Fragment>
        );
    })
}

const BodyService = {
    getEmptyTranslations,
    getBase64,
    checkUniqueArray,
    printTranslations
}

export default BodyService;