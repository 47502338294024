import api from './api';

const getAll = (pagination) => {
    return api.get("region", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("region/list").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`region/${id}`).then((response) => {
        return response.data;
    })
}

const create = (regionDTO) => {
    return api.post("region", regionDTO).then((response) => {
        return response.data;
    });
}

const update = (id, region) => {
    return api.put(`region/${id}`, region).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`region/${id}`).then((response) => {
        return response.data;
    })
}

const RegionService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    deleteById
}

export default RegionService;