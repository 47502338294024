import api from './api';

const getAllByFamily = (id) => {
    return api.get(`category/family/${id}/list`).then((response) => {
        return response.data;
    });
}

const update = (id, categoryFamilyList) => {
    return api.put(`family/${id}/order`, categoryFamilyList, null, false).then((response) => {
        return response.data;
    })
}

const FamilyCategoryService = {
    getAllByFamily,
    update
}

export default FamilyCategoryService;
