import {t} from "i18next";
import {Alert, Button, Col, Container, Form, FormGroup, FormLabel, Image, Row} from "react-bootstrap";
import BodyService from "../../services/body.service";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {v4 as uuidv4} from "uuid";
import {Fragment, useEffect, useRef, useState} from "react";
import CategoryService from "../../services/category.service";
import {useForm} from "react-hook-form";
import AttributeService from "../../services/attribute.service";
import TranslationInput from "./fields/TranslationInput";
import TranslationDescriptionInput from "./fields/TranslationDescriptionInput";

import ReactTooltip from "react-tooltip";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import ProductService from "../../services/product.service";
import useScript from "../../hooks/useScript";

const ProductForm = ({product, translations, onSubmit, details, imageList, setImageList}) => {

    const [animationParent] = useAutoAnimate()

    const {register, handleSubmit, formState, reset, setValue, getValues} = useForm({mode: "all"});

    useScript("https://clippingmagic.com/api/v1/ClippingMagic.js");

    const [categories, setCategories] = useState();
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [attributes, setAttributes] = useState();
    const [attributesSelected, setAttributesSelected] = useState([]);
    const [typePrice, setTypePrice] = useState();
    const [orderIsValid, setOrderIsValid] = useState(false);
    const [unitPrice, setUnitPrice] = useState("");
    const [halfPortion, setHalfPortion] = useState("");
    const [nameTranslations, setNameTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [halfPortionTranslations, setHalfPortionTranslations] = useState([]);
    const [onlyImage, setOnlyImage] = useState();
    const [imageBase64, setImageBase64] = useState();
    const [imageName, setImageName] = useState();
    const [imageContentType, setImageContentType] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [onlyUrl, setOnlyUrl] = useState();
    const [productUrl, setProductUrl] = useState();
    const [active, setActive] = useState(false);
    const [activeValuation, setActiveValuation] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);
    const indexImage = useRef();

    const typePrices = [{
        id: 1,
        name: t("translation:product.creation.fields.typePrice.unit")
    }, {
        id: 2,
        name: t("translation:product.creation.fields.typePrice.person")
    }, {
        id: 3,
        name: t("translation:product.creation.fields.typePrice.market")
    }, {
        id: 4,
        name: t("translation:product.creation.fields.typePrice.liqueurs")
    }
    , {
        id: 5,
        name: t("translation:product.creation.fields.typePrice.noPrice")
    }
    ];

    const editImage = async (opts) => {
        switch (opts.event) {
            case "error":
                console.log("Error")
                break;

            case "result-generated":
                let editedImage = opts.image;
                let imageId = parseInt(editedImage.id);
                let clipImage = await ProductService.getImage(imageId);
                const copyImageList = [...imageList];
                const copyImage = {...copyImageList[indexImage.current]};
                copyImage.imageBase64 = clipImage.base64;
                copyImage.imageContentType = "image/png";
                copyImage.imageName = clipImage.name;
                copyImage.url = null;
                copyImage.clippingId = clipImage.id;
                copyImage.clippingSecret = clipImage.secret;
                copyImageList[indexImage.current] = copyImage;
                setImageList(copyImageList);
                break;

            case "editor-exit":
                console.log("Exit")
                break;
        }
    }

    const cutImage = async (image, index, newItem) => {
        indexImage.current = index;
        if (newItem) {
            let clipImage = await ProductService.cutImage(image)

            const copyImageList = [...imageList];
            const copyImage = {...copyImageList[index]};
            copyImage.imageBase64 = clipImage.base64;
            copyImage.imageContentType = "image/png";
            copyImage.imageName = clipImage.name;
            copyImage.url = null;
            copyImage.clippingId = clipImage.id;
            copyImage.clippingSecret = clipImage.secret;
            copyImageList[index] = copyImage;
            setImageList(copyImageList);
        } else {
            window.ClippingMagic.initialize({apiId: 14162});
            window.ClippingMagic?.edit({
                "image": {
                    "id": parseInt(image.clippingId),
                    "secret": image.clippingSecret
                },
                "useStickySettings": true,
                "locale": "es-ES"
            }, editImage);
        }

    }

    const getAttributes = async () => {
        const data = await AttributeService.getAllList();
        setAttributes(data);
    }

    const setImageToBase64 = async (file, index) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            const copyImageList = [...imageList];
            const copyImage = {...copyImageList[index]};
            copyImage.imageBase64 = base.split(',')[1];
            copyImage.imageName = file.name;
            copyImage.imageContentType = file.type;
            copyImage.clippingId = null;
            copyImage.clippingSecret = null;
            copyImageList[index] = copyImage;
            setImageList(copyImageList);
        }
    }

    const setOnlyImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setImageBase64(base.split(',')[1]);
            setImageName(file.name);
            setImageContentType(file.type);
        }
    }

    const setOrder = (index, order) => {
        const copyImageList = [...imageList];
        copyImageList[index].order = parseInt(order);
        setImageList(copyImageList);
    }

    const addImage = () => {
        setImageList([...imageList, {
            uuid: uuidv4(),
            order: imageList.length + 1
        }]);
    }

    const removeImage = (index) => {
        const order = imageList[index].order;
        const copyImageList = [...imageList];
        copyImageList.splice(index, 1);
        copyImageList.forEach((image, idx) => {
            if (image.order > order) {
                image.order--;
            }
        });
        if (copyImageList.length === 0) {
            setImageList([{
                uuid: uuidv4(),
                order: 1
            }]);
        } else {
            setImageList(copyImageList);
        }
    }

    const removeOnlyImage = () => {
        setImageBase64(null);
        setImageName(null);
        setImageContentType(null);
        setImageUrl(null);
    }

    const setAttribute = (attribute) => {
        setValue(`attributes`, attribute);
        setAttributesSelected(getValues("attributes"));
    }

    const getBase64Src = (image) => {
        if (!image?.imageBase64 || !image?.imageContentType) {
            return false;
        }
        return `data:${image.imageContentType};base64,${image.imageBase64}`;
    }

    const setNameTranslationValue = (index, value) => {
        setNameTranslations(translations.nameTranslations.map((nameTranslation, i) => {
            if (i === index) {
                nameTranslation.value = value;
            }
            return nameTranslation;
        }));
    }

    const setDescriptionTranslationValue = (index, value) => {
        setDescriptionTranslations(translations.descriptionTranslations.map((descriptionTranslation, i) => {
            if (i === index) {
                descriptionTranslation.value = value;
            }
            return descriptionTranslation;
        }));
    }

    const setHalfPortionTranslationValue = (index, value) => {
        setHalfPortionTranslations(translations.halfPortionTranslations.map((halfPortionTranslation, i) => {
            if (i === index) {
                halfPortionTranslation.value = value;
            }
            return halfPortionTranslation;
        }));
    }

    const setFormCategories = (category) => {
        setValue(`categories`, category);
        setCategoriesSelected(getValues("categories"));
    }

    const setFormPrice = (e) => {
        if (e.target.value !== null) {
            if (!unitPrice || e.target.value.match(/^\d{0,}(\,|\.){0,1}\d{0,2}?$/)) {
                setValue('unitPrice', e.target.value.replace(',', '.'));
                setUnitPrice(getValues("unitPrice"));
            }
        } else {
            setValue('unitPrice', unitPrice.target.value);
        }
    }

    const setFormHalfPortion = (e) => {
        if (e.target.value !== null) {
            if (!halfPortion || e.target.value.match(/^\d{0,}(\,|\.){0,1}\d{0,2}?$/)) {
                setValue('halfPortion', e.target.value.replace(',', '.'));
                setHalfPortion(getValues("halfPortion"));
            }
        } else {
            setValue('halfPortion', halfPortion.target.value);
        }
    }

    const getCategories = async () => {
        const data = await CategoryService.getAllList();
        setCategories(data);
    }

    const handleOnlyImage = (e) => {
        setOnlyImage(e.target.checked);
        if (onlyUrl) {
            setOnlyUrl(false);
        }
    }

    const handleOnlyUrl = (e) => {
        setOnlyUrl(e.target.checked);
        if (onlyImage) {
            setOnlyImage(false);
        }
    }

    const onSubmitHandle = async (data) => {
        if (onlyUrl) {
            data.url = productUrl
        } else {
            data.url = null;
        }
        if (onlyImage) {
            data.imageName = imageName;
            data.imageBase64 = imageBase64;
            data.imageContentType = imageContentType;
            data.imageUrl = imageUrl;
        }
        data.nameTranslations = nameTranslations;
        data.descriptionTranslations = descriptionTranslations;
        data.halfPortionTranslations = halfPortionTranslations;
        data.typePrice = typePrice;
        data.unitPrice = unitPrice;
        data.halfPortion = halfPortion;
        data.imageList = imageList;
        data.active = active;
        data.activeValuation = activeValuation;
        await onSubmit(data);
        if (!details) {
            reset();
            setTypePrice(0);
            setNameTranslations([]);
            setDescriptionTranslations([])
            setCategoriesSelected([]);
            setAttributesSelected([]);
            setUnitPrice("");
            setHalfPortion("");
            setDescriptionTranslations([]);
            setHalfPortionTranslations([]);
            setImageList([{
                uuid: uuidv4(),
                order: 1
            }]);
            setActive(false);
            setOnlyUrl(false);
            setProductUrl("");
            setOnlyImage(false);
            removeOnlyImage();
            reset();
        }
    }

    useEffect(() => {
        getCategories();
        if (!details || (details && product)) {
            getAttributes();
            reset({
                ...product
            });
            setAttributesSelected(product?.attributes || []);
            setCategoriesSelected(product?.categories || []);
            setUnitPrice(product?.unitPrice || "");
            setHalfPortion(product?.halfPortion || "");
            setTypePrice(product?.typePrice || "");
            setActive(product?.active || false);
            setActiveValuation(product?.activeValuation || false);
            if (product?.imageUrl != null) {
                setImageUrl(product?.imageUrl);
                setOnlyImage(true);
            }
            if (product?.url != null) {
                setProductUrl(product?.url);
                setOnlyUrl(true);
            }
            setShowAlerts(true);
        }
    }, [product]);

    useEffect(() => {
        if (!details || (details && product !== {})) {
            setNameTranslations(translations.nameTranslations);
            setDescriptionTranslations(translations.descriptionTranslations);
            setHalfPortionTranslations(translations.halfPortionTranslations);
        }
    }, [translations]);

    useEffect(() => {
        if (!details || (details && product !== {})) {
            setOrderIsValid(BodyService.checkUniqueArray(imageList.map(image => image.order)));
        }
    }, [imageList]);

    return (
        <>
            {(!onlyImage && !onlyUrl) &&
            <Form onSubmit={handleSubmit(onSubmitHandle)}>
                <p className="text-info">
                    {t('translation:product.creation.fields.infoFields')}
                </p>
                <Row>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.reference')} * : </Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo2"
                                             className="infoDialog"/>
                            <ReactTooltip id="referenceInfo2" place="top" effect="solid">
                                {t("translation:product.creation.fields.referenceInfo")}
                            </ReactTooltip>
                            <Form.Control type="text" {...register("name", {
                                required: true,
                                minLength: 3
                            })}/>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                            <Form.Check className="mt-1" type="switch"
                                        checked={active} onChange={() => {
                                            setActive(!active);
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.imageOnly')}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="onlyImage"
                                             className="infoDialog"/>
                            <ReactTooltip id="onlyImage" place="top" effect="solid">
                                {t("translation:product.creation.fields.onlyImageInfo")}
                            </ReactTooltip>
                            <Form.Check className="mt-1" type="switch" checked={onlyImage} onChange={handleOnlyImage}/>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.urlOnly')}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="onlyUrl"
                                             className="infoDialog"/>
                            <ReactTooltip id="onlyUrl" place="top" effect="solid">
                                {t("translation:product.creation.fields.onlyUrlInfo")}
                            </ReactTooltip>
                            <Form.Check className="mt-1" type="switch" checked={onlyUrl} onChange={handleOnlyUrl}/>
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <div>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                                     className="infoDialog m-0"/>
                    <ReactTooltip id="translationInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.translationInfo")}
                    </ReactTooltip>
                    {translations.nameTranslations?.length > 0 ?
                        <div>
                            <Row className="mt-2">
                                {nameTranslations?.length > 0 &&
                                <Col className="mb-2" md="auto">
                                    <label>{t('translation:general.fields.nameTranslations')} :</label>
                                </Col>
                                }
                                <Row className="mb-3" md="auto">
                                    <TranslationInput translations={nameTranslations || []}
                                                      onChange={setNameTranslationValue}/>
                                </Row>
                            </Row>
                            <Row>
                                {descriptionTranslations?.length > 0 &&
                                <Col className="mb-2" md="auto">
                                    <label>{t('translation:product.list.description')} :</label>
                                </Col>
                                }
                                <Row className="mb-3" md="auto">
                                    <TranslationDescriptionInput translations={descriptionTranslations || []}
                                                                 onChange={setDescriptionTranslationValue}/>
                                </Row>
                            </Row>
                        </div>
                        :
                        <Alert variant="danger" className="mt-2" show={showAlerts}>
                            {t('translation:general.emptyTranslations')}
                        </Alert>
                    }
                    <hr/>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="priceInfo"
                                     className="infoDialog m-0"/>
                    <ReactTooltip id="priceInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.priceInfo")}
                    </ReactTooltip>
                    <Row className="mb-4 mt-2">
                        <Col md="auto">
                            <FormGroup>
                                <Form.Label>{t('translation:product.creation.fields.typePrice.title')} *</Form.Label>
                                <Form.Select value={typePrice} onChange={(e) => setTypePrice(parseInt(e.target.value))}>
                                    {!typePrice && <option></option>}
                                    {typePrices.map(typePrice => (
                                        <option key={typePrice.id} value={typePrice.id}>{typePrice.name}</option>
                                    ))}
                                </Form.Select>
                            </FormGroup>
                        </Col>
                        <Col md="auto">
                            <FormGroup>
                                <Form.Label>{(typePrice === 4) ? t('translation:product.creation.fields.bottle') : t('translation:product.creation.fields.unitPrice')} :</Form.Label>
                                <Form.Control type="text" value={unitPrice}
                                              onChange={setFormPrice}/>
                            </FormGroup>
                        </Col>
                        {(typePrice === 1 || typePrice === 4) &&
                        <Col md="auto">
                            <FormGroup>
                                <Form.Label>{(typePrice === 1) ? t('translation:product.creation.fields.halfPortion') : t('translation:product.creation.fields.glass')} :</Form.Label>
                                <Form.Control type="text" value={halfPortion}
                                              onChange={setFormHalfPortion}/>
                            </FormGroup>
                        </Col>
                        }
                    </Row>
                    {(translations.halfPortionTranslations?.length > 0 && halfPortion && typePrice === 1) &&
                    <div>
                        <Row className="mt-2">
                            {(halfPortionTranslations?.length > 0) &&
                            <Col className="mb-2" md="auto">
                                <label>{t('translation:general.fields.halfPortionTranslations')} :</label>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="halfPriceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="halfPriceInfo" place="right" effect="solid">
                                    {t("translation:product.creation.fields.halfPortionTranslationsInfo")}
                                </ReactTooltip>
                            </Col>
                            }
                            <Row className="mb-3" md="auto">
                                <TranslationInput translations={halfPortionTranslations || []}
                                                  onChange={setHalfPortionTranslationValue}/>
                            </Row>
                        </Row>
                    </div>
                    }
                    <hr/>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="categoryInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="categoryInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.categoryInfo")}
                    </ReactTooltip>
                    <Row className="mt-2 mb-4">
                        <Col md="6" lg="4">
                            <Form.Label>{t('translation:product.creation.fields.category')} *</Form.Label>
                            {categories?.length > 0 &&
                            <Select options={categories}
                                    isMulti={true}
                                    classNamePrefix="select"
                                    closeMenuOnSelect={false}
                                    placeholder=""
                                    value={categoriesSelected}
                                    noOptionsMessage={() => t('general.empty')}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    onChange={setFormCategories}/>
                            }
                        </Col>
                        <Col md="6" lg="4">
                            <Form.Label>{t('translation:product.creation.fields.attribute')}</Form.Label>
                            {attributes?.length > 0 ?
                                <Select options={attributes}
                                        isMulti={true}
                                        classNamePrefix="select"
                                        closeMenuOnSelect={false}
                                        placeholder=""
                                        value={attributesSelected}
                                        noOptionsMessage={() => t('general.empty')}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={setAttribute}/>
                                :
                                <Row>
                                    <Form.Label>{t('translation:product.creation.fields.noAttribute')}</Form.Label>
                                </Row>
                            }
                        </Col>
                        <Col md="auto">
                            <FormGroup>
                                <Form.Label>{t('translation:product.creation.fields.activeValuation')}</Form.Label>
                                <Form.Check className="mt-1" type="switch"
                                            checked={activeValuation} onChange={() => {
                                    setActiveValuation(!activeValuation);
                                }}/>
                            </FormGroup>
                        </Col>
                        <Col md="auto">
                            <FormGroup>
                                <Form.Label>{t('translation:product.creation.fields.valuation')}</Form.Label>
                                <Form.Select {...register("valuation")}>
                                    {[...Array(5)].map((value, i) =>
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    )}
                                </Form.Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className={"mt-3"}>
                        <Col>
                            <Form.Label>{t("translation:product.creation.fields.images")}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="valuationInfo"
                                             className="infoDialog"/>
                            <ReactTooltip id="valuationInfo" place="right" effect="solid">
                                {t("translation:product.creation.fields.imagesInfo")}
                            </ReactTooltip>
                        </Col>
                    </Row>
                    <div ref={animationParent}>
                        {imageList?.map((image, index) => (
                            <Fragment key={image.uuid || image.id}>
                                <Row className="mb-3">
                                    {imageList.length > 1 &&
                                    <>
                                        <Col md="auto" className="align-self-center">
                                            <Form.Label>{t("translation:product.creation.fields.order")} :</Form.Label>
                                        </Col>
                                        <Col md="auto">
                                            <Form.Select value={image.order}
                                                         onChange={e => setOrder(index, e.target.value)}>

                                                {[...Array(imageList.length)].map((position, i) => (
                                                    <option key={i} value={i + 1}>{i + 1}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </>
                                    }
                                    {(image?.imageBase64 || image?.url) &&
                                    <Row className="mt-3">
                                        <Col className="col-8 col-md-6 col-lg-3">
                                            <Image fluid thumbnail src={getBase64Src(image) || image.url}
                                                   alt={product?.name}/>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="align-self-end">
                                                    {(image?.clippingId) ?
                                                        <Button variant="link" size="ls"
                                                                onClick={() => cutImage(image, index, false)}
                                                                className="border-primary me-2">
                                                            <FontAwesomeIcon icon="fas fa-edit"/>
                                                        </Button>
                                                        :
                                                        <Button variant="link" size="ls"
                                                                onClick={() => cutImage(image, index, true)}
                                                                className="border-primary me-2">
                                                            <FontAwesomeIcon icon="fas fa-cut"/>
                                                        </Button>
                                                    }
                                                    <Button variant="link" size="ls" onClick={() => removeImage(index)}
                                                            className="border-primary">
                                                        <FontAwesomeIcon icon="fas fa-trash"/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    }
                                </Row>
                                <Row>
                                    <Col md="auto">
                                        <Form.Control type="file"
                                                      accept="image/png, image/jpeg"
                                                      onChange={(e) => setImageToBase64(e.target.files[0], index)}/>
                                    </Col>
                                    {(!(image?.url || image?.imageBase64) && imageList.length > 1) &&
                                    <Col md="auto">
                                        <Button variant="link" size="ls" onClick={() => removeImage(index)}
                                                className="border-primary">
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </Col>
                                    }
                                </Row>
                                {(index === imageList.length - 1 && (image?.url || image?.imageBase64)) &&
                                <Row>
                                    <Col md="auto">
                                        <FormGroup onClick={addImage}>
                                            <Form.Label
                                                style={{cursor: "pointer"}}>{t('product.creation.fields.addImage')}</Form.Label>
                                            <Button variant="link" size="lg" className={"ms-2"}>
                                                <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                }
                                <hr/>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className={"mt-4"}>
                    {(!formState.isValid || !orderIsValid || !typePrice || !categoriesSelected.length > 0) &&
                    <p className="infoDisabledButton"> {t('translation:product.creation.fields.requiredFields')}</p>
                    }
                    <Button type="submit"
                            disabled={!formState.isValid || !orderIsValid || !typePrice || !categoriesSelected.length > 0}
                    >
                        {!details ? t('translation:product.creation.buttonCreate') : t('translation:product.creation.buttonUpdate')}
                    </Button>
                </div>
            </Form>
            }
            {onlyImage &&
            <Form onSubmit={handleSubmit(onSubmitHandle)}>
                <p className="text-info">
                    {t('translation:product.creation.fields.infoFields')}
                </p>
                <Row>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.reference')} * : </Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo2"
                                             className="infoDialog"/>
                            <ReactTooltip id="referenceInfo2" place="top" effect="solid">
                                {t("translation:product.creation.fields.referenceInfo")}
                            </ReactTooltip>
                            <Form.Control type="text" {...register("name", {
                                required: true,
                                minLength: 3,
                                maxLength: 50
                            })}/>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                            <Form.Check className="mt-1" type="switch"
                                        checked={active} onChange={() => {
                                setActive(!active);
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.imageOnly')}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="onlyImage"
                                             className="infoDialog"/>
                            <ReactTooltip id="onlyImage" place="top" effect="solid">
                                {t("translation:product.creation.fields.onlyImageInfo")}
                            </ReactTooltip>
                            <Form.Check className="mt-1" type="switch" checked={onlyImage} onChange={handleOnlyImage}/>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.urlOnly')}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="onlyUrl"
                                             className="infoDialog"/>
                            <ReactTooltip id="onlyUrl" place="top" effect="solid">
                                {t("translation:product.creation.fields.onlyUrlInfo")}
                            </ReactTooltip>
                            <Form.Check className="mt-1" type="switch" checked={onlyUrl} onChange={handleOnlyUrl}/>
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <div>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                                     className="infoDialog m-0"/>
                    <ReactTooltip id="translationInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.translationInfo")}
                    </ReactTooltip>
                    {translations.nameTranslations?.length > 0 ?
                        <div>
                            <Row className="mt-2">
                                {nameTranslations?.length > 0 &&
                                <Col className="mb-2" md="auto">
                                    <label>{t('translation:general.fields.nameTranslations')} :</label>
                                </Col>
                                }
                                <Row className="mb-3" md="auto">
                                    <TranslationInput translations={nameTranslations || []}
                                                      onChange={setNameTranslationValue}/>
                                </Row>
                            </Row>
                            <Row>
                                {descriptionTranslations?.length > 0 &&
                                <Col className="mb-2" md="auto">
                                    <label>{t('translation:product.list.description')} :</label>
                                </Col>
                                }
                                <Row className="mb-3" md="auto">
                                    <TranslationDescriptionInput translations={descriptionTranslations || []}
                                                                 onChange={setDescriptionTranslationValue}/>
                                </Row>
                            </Row>
                        </div>
                        :
                        <Alert variant="danger" className="mt-2" show={showAlerts}>
                            {t('translation:general.emptyTranslations')}
                        </Alert>
                    }
                    <hr/>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="categoryInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="categoryInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.categoryInfo")}
                    </ReactTooltip>
                    <Row className="mt-2 mb-4">
                        <Col md="6" lg="4">
                            <Form.Label>{t('translation:product.creation.fields.category')} *</Form.Label>
                            {categories?.length > 0 &&
                            <Select options={categories}
                                    isMulti={true}
                                    classNamePrefix="select"
                                    closeMenuOnSelect={false}
                                    placeholder=""
                                    value={categoriesSelected}
                                    noOptionsMessage={() => t('general.empty')}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    onChange={setFormCategories}/>
                            }
                        </Col>
                    </Row>
                    <Form.Label className="mb-2">{t("translation:general.fields.image")}</Form.Label>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                     data-for="imageInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="imageInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.imageInfoDetail")}
                    </ReactTooltip>
                    <Row className="mt-1">
                        {(imageUrl|| imageBase64) &&
                        <Col className="col-8 col-md-6 col-lg-2">
                            <Image fluid thumbnail src={getBase64Src({
                                imageBase64: imageBase64,
                                imageContentType: imageContentType
                            }) || imageUrl}
                                   alt={product?.name}/>
                        </Col>
                        }
                        {(imageBase64 || imageUrl) && (
                            <Col className="align-self-end">
                                <Button variant="link" size="ls" onClick={removeOnlyImage}
                                        className="border-primary">
                                    <FontAwesomeIcon icon="fas fa-trash"/>
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col md="auto" className="mt-3">
                            <Form.Control type="file"
                                          accept="image/png, image/jpeg"
                                          {...register("image")}
                                          onChange={(e) => setOnlyImageToBase64(e.target.files[0])}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={"mt-4"}>
                    {!formState.isValid &&
                    <p className="infoDisabledButton">{t('translation:product.creation.fields.requiredMinFields')}</p>
                    }
                    <Button type="submit" disabled={!formState.isValid || !categoriesSelected.length > 0}>
                        {!details ? t('translation:product.creation.buttonCreate') : t('translation:product.creation.buttonUpdate')}
                    </Button>
                </div>
            </Form>
            }
            {onlyUrl &&
            <Form onSubmit={handleSubmit(onSubmitHandle)}>
                <p className="text-info">
                    {t('translation:product.creation.fields.infoFields')}
                </p>
                <Row>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.reference')} * : </Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo2"
                                             className="infoDialog"/>
                            <ReactTooltip id="referenceInfo2" place="top" effect="solid">
                                {t("translation:product.creation.fields.referenceInfo")}
                            </ReactTooltip>
                            <Form.Control type="text" {...register("name", {
                                required: true,
                                minLength: 3,
                                maxLength: 50
                            })}/>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                            <Form.Check className="mt-1" type="switch"
                                        checked={active} onChange={() => {
                                setActive(!active);
                            }}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.imageOnly')}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="onlyImage"
                                             className="infoDialog"/>
                            <ReactTooltip id="onlyImage" place="top" effect="solid">
                                {t("translation:product.creation.fields.onlyImageInfo")}
                            </ReactTooltip>
                            <Form.Check className="mt-1" type="switch" checked={onlyImage} onChange={handleOnlyImage}/>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Form.Label>{t('translation:product.creation.fields.urlOnly')}</Form.Label>
                            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="onlyUrl"
                                             className="infoDialog"/>
                            <ReactTooltip id="onlyUrl" place="top" effect="solid">
                                {t("translation:product.creation.fields.onlyUrlInfo")}
                            </ReactTooltip>
                            <Form.Check className="mt-1" type="switch" checked={onlyUrl} onChange={handleOnlyUrl}/>
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <div>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                                     className="infoDialog m-0"/>
                    <ReactTooltip id="translationInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.translationInfo")}
                    </ReactTooltip>
                    {translations.nameTranslations?.length > 0 ?
                        <div>
                            <Row className="mt-2">
                                {nameTranslations?.length > 0 &&
                                <Col className="mb-2" md="auto">
                                    <label>{t('translation:general.fields.nameTranslations')} :</label>
                                </Col>
                                }
                                <Row className="mb-3" md="auto">
                                    <TranslationInput translations={nameTranslations || []}
                                                      onChange={setNameTranslationValue}/>
                                </Row>
                            </Row>
                            <Row>
                                {descriptionTranslations?.length > 0 &&
                                <Col className="mb-2" md="auto">
                                    <label>{t('translation:product.list.description')} :</label>
                                </Col>
                                }
                                <Row className="mb-3" md="auto">
                                    <TranslationDescriptionInput translations={descriptionTranslations || []}
                                                                 onChange={setDescriptionTranslationValue}/>
                                </Row>
                            </Row>
                        </div>
                        :
                        <Alert variant="danger" className="mt-2" show={showAlerts}>
                            {t('translation:general.emptyTranslations')}
                        </Alert>
                    }
                    <hr/>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="categoryInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="categoryInfo" place="right" effect="solid">
                        {t("translation:product.creation.fields.categoryInfo")}
                    </ReactTooltip>
                    <Row className="mt-2 mb-4">
                        <Col md="6" lg="4">
                            <Form.Label>{t('translation:product.creation.fields.category')} *</Form.Label>
                            {categories?.length > 0 &&
                            <Select options={categories}
                                    isMulti={true}
                                    classNamePrefix="select"
                                    closeMenuOnSelect={false}
                                    placeholder=""
                                    value={categoriesSelected}
                                    noOptionsMessage={() => t('general.empty')}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    onChange={setFormCategories}/>
                            }
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md="auto">
                            <Form.Label>{t("translation:company.fields.socialNetworkPlaceholder")}</Form.Label>
                            <Form.Control type="text" onChange={(e) => {
                                setProductUrl(e.target.value)
                            }}
                            defaultValue={productUrl}/>
                        </Col>
                    </Row>
                </div>
                <div className={"mt-4"}>
                    {(!formState.isValid || !productUrl) &&
                    <p className="infoDisabledButton">{t('translation:product.creation.fields.requiredMinFields')}</p>
                    }
                    <Button type="submit" disabled={!formState.isValid || !productUrl || !categoriesSelected.length > 0}>
                        {!details ? t('translation:product.creation.buttonCreate') : t('translation:product.creation.buttonUpdate')}
                    </Button>
                </div>
            </Form>
            }
        </>
    );
}

export default ProductForm;