import {Accordion, Alert, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../scripts/constants";
import ProductForm from "../../components/forms/ProductForm";
import PaginationComponent from "../../components/PaginationComponent";

import BodyService from "../../services/body.service";
import ProductService from "../../services/product.service";

import {v4 as uuidv4} from "uuid";
import AccordionToggle from "../../components/AccordionToggle";
import CategoryService from "../../services/category.service";
import LanguageService from "../../services/languages.service";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";

const Product = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [nameTranslations, setNameTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [halfPortionTranslations, setHalfPortionTranslations] = useState([]);
    const accordionRef = useRef(null);
    const [nameToSearch, setNameToSearch] = useState('');
    const [categoryToSearch, setCategoryToSearch] = useState('');
    const [languages, setLanguages] = useState([]);

    const [imageList, setImageList] = useState([{
        uuid: uuidv4(),
        order: 1
    }]);

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
        update: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        update: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const handleCategoryToSearch = (category) => {
        if (category === undefined) {
            setCategoryToSearch("");
        } else {
            setCategoryToSearch(category);
        }
    }

    const handleNameToSearch = (name) => {
        if (name === undefined) {
            setNameToSearch("");
        } else {
            setNameToSearch(name);
        }
    }

    const handlePaginationProduct = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const getLanguages = async () => {
        const data = await LanguageService.getAll();
        setLanguages(data);
    }

    const getEmptyTranslationsName = async () => {
        const data = await BodyService.getEmptyTranslations();
        setNameTranslations(data);
    }

    const getEmptyTranslationsDescription = async () => {
        const data = await BodyService.getEmptyTranslations();
        setDescriptionTranslations(data);
    }

    const getEmptyHalfPortionTranslations = async () => {
        const data = await BodyService.getEmptyTranslations();
        setHalfPortionTranslations(data);
    }

    const getProducts = async () => {
        const data = await ProductService.getAll(pagination);
        setProducts(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const getCategories = async () => {
        const data = await CategoryService.getAllList();
        setCategories(data);
    }

    const createProduct = async (data) => {
        try {
            data.productImages = imageList;
            data.nameTranslations = nameTranslations;
            data.descriptionTranslations = descriptionTranslations;
            data.halfPortionTranslations = halfPortionTranslations;
            await ProductService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getEmptyTranslationsName();
            getEmptyTranslationsDescription();
            getEmptyHalfPortionTranslations();
            getProducts();
            setImageList([{
                id: uuidv4(),
                order: 1
            }]);
        } catch (e) {
            setErrors({...errors, create: true});
            throw e;
        }
    };

    const deleteProduct = (id) => {
        showModal({
            body: t("translation:notificationModel.product"),
            onConfirm: async () => {
                try {
                    await ProductService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getEmptyTranslationsName();
                    getEmptyTranslationsDescription();
                    getProducts();
                } catch (e) {
                    setErrors({...errors, delete: true});
                }
            }
        })
    }

    const handleUpdateProduct = (product, index) => async () => {
        try {
            let tmpObject = {
                active: !product.active
            };
            let copyProducts = products;
            copyProducts[index].active = !copyProducts[index].active
            setProducts(copyProducts);
            await ProductService.updateActive(product.id, tmpObject);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    const searchProducts = async (e) => {
        e.preventDefault();
        const data = await ProductService.getAllByCategoryAndName(categoryToSearch, nameToSearch, pagination);
        setProducts(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    useEffect(() => {
        getProducts();
        getCategories();
    }, [pagination.currentPage, pagination.itemsPerPage])

    useEffect(() => {
        getEmptyTranslationsName();
        getEmptyTranslationsDescription();
        getEmptyHalfPortionTranslations();
        getLanguages();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:product.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('product.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:product.success.create')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:product.error.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:product.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:product.error.delete')}
            </Alert>
            <Alert variant="success" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('translation:product.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('translation:product.error.update')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6
                            className={"m-0 fw-bold text-primary"}>{t('translation:product.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ProductForm
                                translations={{nameTranslations, descriptionTranslations, halfPortionTranslations}}
                                onSubmit={createProduct} imageList={imageList}
                                setImageList={setImageList}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <Row>
                        <Col xs={12} md={4} className="mb-2 mb-md-2 mb-lg-0">
                            <h6 className={"m-0 fw-bold text-primary"}>{t('translation:product.list.title')}</h6>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-2 mb-md-0">
                            {categories?.length > 0 &&
                            <Form.Select onChange={e => handleCategoryToSearch(e.target.value)}>
                                <option></option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </Form.Select>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <Form className="input-group rounded" onSubmit={e => searchProducts(e)}>
                                <input type="search" className="form-control rounded" placeholder="Buscar"
                                       aria-label="Buscar" onChange={e => handleNameToSearch(e.target.value)}/>
                                <Button type="submit" className="input-group-text border-0">
                                    <FontAwesomeIcon icon="fas fa-search"/>
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col" className={"col-1"}>
                                <span>{t('translation:general.fields.active')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="activeInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="activeInfo" place="top" effect="solid">
                                    {t("translation:general.info.activeProduct")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:product.creation.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.nameTranslations')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="languageInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="languageInfo" place="top" effect="solid">
                                    {t("translation:general.info.language")}
                                </ReactTooltip>
                            </th>
                            <th scope="col" className="text-center col-2">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {products.length > 0 ? products.map((product, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check type="switch" onChange={handleUpdateProduct(product, index)}
                                                    checked={product.active}>
                                        </Form.Check>
                                    </td>
                                    <td>{product.name}</td>
                                    <td>
                                        {BodyService.printTranslations(languages, product.nameTranslations, index)}
                                    </td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/product/" + product.id}
                                                className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-1 ms-lg-2 border-primary"
                                                value={product.id}
                                                onClick={() => deleteProduct(product.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('translation:general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={products.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationProduct}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Product;
