import api from './api';

const get = () => {
    return api.get(`company`).then((response) => {
        return response.data;
    })
}

const update = (company) => {
    return api.put(`company`, company).then((response) => {
        return response.data;
    })
}

const CompanyService = {
    get,
    update,
}

export default CompanyService;