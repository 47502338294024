import api from "./api";

const getAll = () => {
    return api.get("attribute-type").then((response) => {
        return response.data;
    });
}

// const getAll = (pagination) => {
//     return api.get("category", {
//         pageNum: pagination.currentPage - 1,
//         pageLength: pagination.itemsPerPage
//     }).then((response) => {
//         return response.data;
//     });
// }

const create = (attributeType) => {
    return api.post("attribute-type", attributeType).then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`attribute-type/${id}`).then((response) => {
        return response.data;
    })
}

const update = (id, attributeType) => {
    return api.put(`attribute-type/${id}`, attributeType).then((response) => {
        return response.data;
    })
}

const remove = (id) => {
    return api.delete(`attribute-type/${id}`).then((response) => {
        return response.data;
    })
}

const AttributeTypeService = {
    getAll,
    getById,
    create,
    update,
    remove
}

export default AttributeTypeService;