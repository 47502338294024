import api from './api';

const getAll = (pagination) => {
    return api.get("attribute", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("attribute/list").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`attribute/${id}`).then((response) => {
        return response.data;
    })
}

const create = (attribute) => {
    return api.post("attribute", attribute).then((response) => {
        return response.data;
    });
}

const update = (id, attribute) => {
    return api.put(`attribute/${id}`, attribute).then((response) => {
        return response.data;
    })
}

const updateActive = (id, attribute) => {
    return api.put(`attribute/${id}/active`, attribute).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`attribute/${id}`).then((response) => {
        return response.data;
    })
}

const getAllByName = (attributeName, pagination) => {
    if (attributeName === null) {
        getAll(pagination);
    } else {
        return api.get("attribute/search/", {
            attributeName,
            pageNum: pagination.currentPage - 1,
            pageLength: pagination.itemsPerPage
        }).then((response) => {
            return response.data;
        })
    }
}

const AttributeService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    updateActive,
    deleteById,
    getAllByName
}

export default AttributeService;