import {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Card, Col, Form, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


import APP_CONF from "../../scripts/constants";
import RegionForm from "../../components/forms/RegionForm";
import RegionService from "../../services/region.service";
import PaginationComponent from "../../components/PaginationComponent";
import BodyService from "../../services/body.service";
import AccordionToggle from "../../components/AccordionToggle";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";


const Region = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        duplicate: false,
        linked: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [regions, setRegions] = useState([]);

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const getRegions = async () => {
        const data = await RegionService.getAll(pagination);
        setRegions(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const handlePaginationRegion = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const createRegion = async (data) => {
        try {
            if (data.image?.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            await RegionService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getRegions();
        } catch (e) {
            console.error(e);
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, create: true});
            }
            throw e;
        }
    };

    const deleteRegion = async (id) => {
        showModal({
            body: t("translation:notificationModel.region"),
            onConfirm: async () => {
                try {
                    await RegionService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getRegions();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true});
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    useEffect(() => {
        getRegions();
    }, [pagination.currentPage, pagination.itemsPerPage])

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:region.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('region.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:region.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:region.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:region.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:region.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('translation:region.error.duplicate')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:region.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('region.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <RegionForm onSubmit={createRegion}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('region.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col">
                                <span>{t('translation:general.index')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:region.creation.fields.name')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo" place="top" effect="solid">
                                    {t("translation:region.creation.fields.infoFields")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.image')}</span>
                            </th>
                            <th scope="col" className="text-center col-2">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {regions.length > 0 ? regions.map((region, index) => (
                                <tr key={index}>
                                    <td>{(index + (pagination.currentPage - 1) * pagination.itemsPerPage) + 1}</td>
                                    <td>{region.name}</td>
                                    <td className="w-25">
                                        {region.imageUrl &&
                                        <>
                                            <Image fluid thumbnail src={region.imageUrl} alt={region.name}
                                                   className="d-none d-md-flex w-50"/>
                                            <Image fluid thumbnail src={region.imageUrl} alt={region.name}
                                                   className="d-flex d-md-none"/>
                                        </>
                                        }
                                    </td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" className="border-primary" as={Link}
                                                to={"/region/" + region.id}>
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary"
                                                value={region.id} onClick={() => deleteRegion(region.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={regions.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationRegion}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Region;
