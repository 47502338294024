import {Accordion, Alert, Button, Card, Col, Form, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../scripts/constants";
import CategoryForm from "../../components/forms/CategoryForm";
import PaginationComponent from "../../components/PaginationComponent";

import BodyService from "../../services/body.service";
import CategoryService from "../../services/category.service";
import AccordionToggle from "../../components/AccordionToggle";
import LanguageService from "../../services/languages.service";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import FamilyService from "../../services/family.service";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";

const Category = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const [categories, setCategories] = useState([]);
    const [families, setFamilies] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [nameToSearch, setNameToSearch] = useState('');
    const [familyToSearch, setFamilyToSearch] = useState('');
    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        duplicate: false,
        linked: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];

    const createCategory = async (data) => {
        try {
            await CategoryService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getEmptyTranslationsName();
            getCategories();
        } catch ({response}) {
            if (response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, create: true});
            }
            throw response;
        }
    };

    const deleteCategory = async (id) => {
        showModal({
            body: t("translation:notificationModel.category"),
            onConfirm: async () => {
                try {
                    await CategoryService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getCategories();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true});
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    const getCategories = async () => {
        const data = await CategoryService.getAll(pagination);
        await setCategories(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const getFamilies = async () => {
        const data = await FamilyService.getAll(pagination);
        setFamilies(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const handleFamilyToSearch = (family) => {
        if (family === undefined) {
            setFamilyToSearch("");
        } else {
            setFamilyToSearch(family);
        }
    }

    const handleNameToSearch = (name) => {
        if (name === undefined) {
            setNameToSearch("");
        } else {
            setNameToSearch(name);
        }
    }

    const handlePaginationCategory = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const getLanguages = async () => {
        const data = await LanguageService.getAll();
        setLanguages(data);
    }

    const getEmptyTranslationsName = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const handleUpdateCategory = (category, index) => async () => {
        try {
            let tmpObject = {
                active: !category.active
            };
            let copyCategories = categories;
            copyCategories[index].active = !copyCategories[index].active;
            setCategories(copyCategories);
            await CategoryService.updateActive(category.id, tmpObject);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    const searchCategory = async (e) => {
        e.preventDefault();
        const data = await CategoryService.getAllByCategoryAndName(familyToSearch, nameToSearch, pagination);
        setCategories(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    useEffect(() => {
        getCategories();
    }, [pagination.currentPage, pagination.itemsPerPage])

    useEffect(() => {
        getFamilies();
        getEmptyTranslationsName();
        getLanguages();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:general.sidebar.category")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('category.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:category.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:category.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:category.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:category.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('translation:category.error.duplicate')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:category.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('category.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <CategoryForm onSubmit={createCategory}
                                          translations={translations}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <Row>
                        <Col xs={12} md={4} className="mb-2 mb-md-2 mb-lg-0">
                            <h6 className={"m-0 fw-bold text-primary"}>{t('category.list.title')}</h6>
                        </Col>
                        <Col xs={12} sm={6} md={4} className="mb-2 mb-md-0">
                            {families?.length > 0 &&
                            <Form.Select onChange={e => handleFamilyToSearch(e.target.value)}>
                                <option></option>
                                {families.map(family => (
                                    <option key={family.id} value={family.id}>{family.name}</option>
                                ))}
                            </Form.Select>
                            }
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <Form className="input-group rounded" onSubmit={e => searchCategory(e)}>
                                <input type="search" className="form-control rounded" placeholder="Buscar"
                                       aria-label="Buscar" onChange={e => handleNameToSearch(e.target.value)}/>
                                <Button type="submit" className="input-group-text border-0">
                                    <FontAwesomeIcon icon="fas fa-search"/>
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col" className={"col-1"}>
                                <span>{t('translation:general.fields.active')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="activeInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="activeInfo" place="top" effect="solid">
                                    {t("translation:general.info.activeCategory")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfoList"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfoList" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.nameTranslations')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="languageInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="languageInfo" place="top" effect="solid">
                                    {t("translation:general.info.language")}
                                </ReactTooltip>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.image')}</span>
                            </th>
                            <th scope="col" className={"text-center col-2"}>
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories.length > 0 ? categories.map((category, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check type="switch" onChange={handleUpdateCategory(category, index)}
                                                    checked={category.active}>
                                        </Form.Check>
                                    </td>
                                    <td>{category.name}</td>
                                    <td>
                                        {BodyService.printTranslations(languages, category.nameTranslations, index)}
                                    </td>
                                    <td className="w-25">
                                        <Image fluid thumbnail
                                               src={category.imageUrl ? category.imageUrl : '/img/no_image.jpg'}
                                               alt={category.name} className="d-none d-md-flex w-50"/>
                                        <Image fluid thumbnail
                                               src={category.imageUrl ? category.imageUrl : '/img/no_image.jpg'}
                                               alt={category.name} className="d-flex d-md-none"/>
                                    </td>
                                    <td className="text-center">
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/category/" + category.id}
                                                className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary" as={Link}
                                                to={"/category-product/" + category.id}>
                                            <FontAwesomeIcon icon="fas fa-sort-amount-down"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary"
                                                value={category.id} onClick={() => deleteCategory(category.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={categories.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationCategory}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Category;
