import {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Card, Col, Form, Image, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../../scripts/constants";
import PaginationComponent from "../../../components/PaginationComponent";
import LocalTypeService from "../../../services/localType.service";
import LocalTypeForm from "../../../components/forms/LocalTypeForm";
import AccordionToggle from "../../../components/AccordionToggle";
import useAlertConfirm from "../../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {Helmet, HelmetData} from "react-helmet-async";


const LocalType = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
        update: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        update: false,
        duplicate: false,
        linked: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const itemsPage = [{
        value: 10
    }, {
        value: 20
    }, {
        value: 50
    }, {
        value: 100
    }];


    const [localType, setLocalType] = useState([]);

    const getLocalType = async () => {
        const data = await LocalTypeService.getAll(pagination);
        await setLocalType(data.content);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const handlePaginationLocalType = (numItems) => {
        setPagination({
            ...pagination,
            currentPage: 1,
            itemsPerPage: numItems.value
        })
    }

    const createLocalType = async (data) => {
        try {
            await LocalTypeService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getLocalType();
        } catch (e) {
            console.error(e);
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, create: true});
            }
            throw e;
        }
    };

    const deleteLocalType = async (id) => {
        showModal({
            body: t("translation:notificationModel.local"),
            onConfirm: async () => {
                try {
                    await LocalTypeService.remove(id);
                    setSuccess({...success, delete: true});
                    getLocalType();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true});
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    useEffect(() => {
        getLocalType();
    }, [pagination.currentPage, pagination.itemsPerPage])

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:localType.title")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t("translation:localType.title")}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('translation:localType.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('translation:localType.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('translation:localType.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('translation:localType.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('translation:localType.error.duplicate')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:region.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6
                            className={"m-0 fw-bold text-primary"}>{t('translation:localType.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <LocalTypeForm onSubmit={createLocalType}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('translation:localType.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col">{t('translation:general.index')}</th>
                            <th scope="col">
                                <span>{t('translation:general.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col" className="text-center col-2">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {localType.length > 0 ? localType.map((localType, index) => (
                                <tr key={index}>
                                    <td>{(index + (pagination.currentPage - 1) * pagination.itemsPerPage) + 1}</td>
                                    <td>{localType.name}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/local-type/" + localType.id} className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-lg-2 border-primary"
                                                value={localType.id} onClick={() => deleteLocalType(localType.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('translation:general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    <Row className="align-items-center justify-content-between">
                        <Col className="col-auto paginationPage">
                            <PaginationComponent pagination={pagination} setPagination={setPagination}
                                                 alwaysShown={localType.length > 0}/>
                        </Col>
                        <Col className="col-auto d-flex">
                            <span className="me-3"> {t("translation:general.itemsSelect")} </span>
                            <Select
                                value={{value: pagination.itemsPerPage}}
                                onChange={handlePaginationLocalType}
                                options={itemsPage}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default LocalType;
