import api from "./api";

const getAll = (pagination) => {
    return api.get("local-type", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("local-type/list").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`local-type/${id}`).then((response) => {
        return response.data;
    })
}

const create = (localType) => {
    return api.post("local-type", localType).then((response) => {
        return response.data;
    });
}

const update = (id, localType) => {
    return api.put(`local-type/${id}`, localType).then((response) => {
        return response.data;
    })
}

const remove =(id) => {
    return api.delete(`local-type/${id}`).then((response) => {

        return response.data;
    })
}

const LocalTypeService = {
    getAll,
    getAllList,
    create,
    getById,
    update,
    remove
}

export default LocalTypeService;