import {Alert, Button, Col, Form, FormGroup, FormLabel, Image, Row} from "react-bootstrap";
import {t} from "i18next";
import {useEffect, useState} from "react";
import BodyService from "../../services/body.service";
import {useForm} from "react-hook-form";
import TranslationInput from "./fields/TranslationInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const BannerForm = ({banner, bannerLength, translations, onSubmit, details}) => {

    const [base64, setBase64] = useState();
    const [bannerDetails, setBannerDetails] = useState();
    const [nameTranslations, setNameTranslations] = useState();
    const [selectedImage, setSelectedImage] = useState(banner?.imageUrl);
    const [showAlerts, setShowAlerts] = useState(false);

    const
        {
            register, handleSubmit, formState, reset, setValue
        }
            = useForm({mode: "all"});

    const setNameTranslationValue = (index, value) => {
        setNameTranslations(translations.map((nameTranslation, i) => {
            if (i === index) {
                nameTranslation.value = value;
            }
            return nameTranslation;
        }));
    }

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
            setSelectedImage(true);
        }
    }

    const removeImage = () => {
        setBase64(null);
        setValue('image', null);
        setValue('imageUrl', null);
        if (banner) {
            setBannerDetails({...banner, imageUrl: null});
        }
        setSelectedImage(false);
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        data.nameTranslations = nameTranslations;
        await onSubmit(data);
        if (!details) {
            reset();
            removeImage();
        }
    }

    useEffect(() => {
        if (!details || (details && banner)) {
            reset({
                ...banner
            });
            setBannerDetails(banner);
            setSelectedImage(details);
            setShowAlerts(true);
        }
    }, [banner]);

    useEffect(() => {
        if (!details || (details && banner !== {})) {
            setNameTranslations(translations);
        }
    }, [translations]);

    useEffect(() => {
        if (!details && bannerLength) {
            setValue("order", bannerLength + 1, {shouldValidate: true});
        } else if (!details) {
            setValue("order", 1, {shouldValidate: true});
        }
    }, [bannerLength]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:banner.creation.fields.infoFields')}
            </p>
            <Row className="mt-1 mb-3">
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.image')}*</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="top" effect="solid">
                            {t("translation:banner.creation.fields.imageInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image")}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:banner.creation.fields.order')}*</Form.Label>
                        <Form.Control
                            type="number" min="1"
                            max="50" {...register("order", {required: true})} />
                    </FormGroup>
                </Col>
                <Row className="mt-3">
                    {(bannerDetails?.imageUrl || base64) &&
                    <Col className="col-8 col-md-6 col-lg-2">
                        <Image fluid thumbnail src={base64 || banner?.imageUrl} alt={banner?.name}/>
                    </Col>
                    }
                    {(base64 || bannerDetails?.imageUrl) &&
                        <Col className="align-self-end">
                            <Button variant="link" size="ls" onClick={removeImage}
                                    className="border-primary">
                                <FontAwesomeIcon icon="fas fa-trash"/>
                            </Button>
                        </Col>
                    }
                </Row>
            </Row>
            <hr/>
            <label>{t('translation:banner.creation.fields.description')} :</label>
            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                             data-for="titleDescriptionInfo"
                             className="infoDialog"/>
            <ReactTooltip id="titleDescriptionInfo" place="top" effect="solid">
                {t("translation:banner.creation.fields.infoTitle")}
            </ReactTooltip>
            {translations?.length > 0 ?
                <Row className="mt-2">
                    <TranslationInput translations={nameTranslations || []} onChange={setNameTranslationValue}/>
                </Row>
                :
                <Alert variant="danger" className="mt-2" show={showAlerts}>
                    {t('translation:general.emptyTranslations')}
                </Alert>
            }
            <div className="mt-4">
                <Button type="submit" disabled={!formState.isValid || !selectedImage}>
                    {!details ? t('translation:banner.creation.buttonCreate') : t('translation:banner.creation.buttonUpdate')}
                </Button>
            </div>
        </Form>
    );
}

export default BannerForm;