import {useContext, useEffect, useState} from "react";
import {Alert, Card, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";

import LocationForm from "../../../components/forms/LocationForm";
import LocationService from "../../../services/location.service";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const LocationDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [title] = useState("Ubicación");
    const [location, setLocation] = useState({})

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
        duplicate: false
    });

    const getLocation = async (id) => {
        try {
            const data = await LocationService.getById(id);
            setLocation(data);
        } catch (e) {
            update({...state, error: t("translation:location.error.notFound")})
            navigate("/location");
        }
    }

    const updateLocation = async (data) => {
        try {
            await LocationService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            getLocation(params.id);
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, update: true});
                console.error(e);
            }
        }
    };

    useEffect(() => {
        getLocation(params.id);
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {location?.name && <title>{location?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/location" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('location.title')}</span>
                </Link>
            </div>
            <Alert variant="success" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('location.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('location.error.update')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('category.error.duplicate')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{title}</h1>
                </Card.Header>
                <Card.Body>
                    <LocationForm location={location} onSubmit={updateLocation} details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LocationDetails;
