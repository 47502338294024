import api from "./api";
import TokenService from "./token.service";

const login = (email, password) => {
    return api
        .post("/auth/login", {
            email,
            password
        })
        .then((response) => {
            if (response.data.token) {
                TokenService.setUser(response.data);
            }

            return response.data;
        });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    login,
    logout,
    getCurrentUser,
};

export default AuthService;
