import {useEffect, useState} from "react";
import {Alert, Button, Card, Form, Modal, FormGroup, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import LanguageService from "../../services/languages.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Language = () => {

    const {t} = useTranslation();

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [originTranslate, setOriginTranslate] = useState();
    const [destinationTranslate, setDestinationTranslate] = useState();

    const handleClose = () => setShow(false);
    const handleCloseConfirm = () => setShowConfirm(false);
    const handleShow = (language) => {
        setShow(true);
        setDestinationTranslate(language.languageId);
        setLanguageAvoidSelect(language);
    }
    const handleShowConfirm = () => {
        setShowConfirm(true);
        setShow(false);
    }

    const [languages, setLanguages] = useState([]);

    const [languageAvoidSelect, setLanguageAvoidSelect] = useState([]);

    const [successUpdate, setSuccessUpdate] = useState({
        create: false,
        translate: false
    });

    const [errorUpdate, setErrorUpdate] = useState({
        create: false,
        translate: false,
        translateApi: false
    });

    const getLanguages = async () => {
        const data = await LanguageService.getAll();
        setLanguages(data);
    }

    const handleUpdateActiveLanguage = (language) => async () => {
        try {
            let tmpObject = {
                active: !language.active,
                showActive: language.showActive
            };
            await LanguageService.update(language.languageId, tmpObject);
            setSuccessUpdate({...successUpdate, create: true});
            getLanguages();
        } catch (e) {
            setErrorUpdate({...errorUpdate, create: true});
        }
    }

    const handleUpdateShowActiveLanguage = (language) => async () => {
        try {
            let tmpObject = {
                active: language.active,
                showActive: !language.showActive
            };
            await LanguageService.update(language.languageId, tmpObject);
            setSuccessUpdate({...successUpdate, create: true});
            getLanguages();
        } catch (e) {
            setErrorUpdate({...errorUpdate, create: true});
        }
    }

    const translateAll = async () => {
        setShowConfirm(false);
        try {
            await LanguageService.translate(originTranslate, destinationTranslate);
            setSuccessUpdate({...successUpdate, translate: true});

            const index = languages.findIndex(language => language.languageId === destinationTranslate);
            let updateLanguages = [...languages];

            updateLanguages[index].numTranslations = 1;

            setLanguages(updateLanguages);

        } catch (e) {
            if (e.response.status === 409) {
                setErrorUpdate({...errorUpdate, translateApi: true})
            } else {
                setErrorUpdate({...errorUpdate, translate: true});
            }
        }
    }

    useEffect(() => {
        getLanguages();
    }, [])

    return (
        <>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('language.title')}</h1>
            </div>
            <Alert variant="success" show={successUpdate.create} dismissible
                   onClose={() => setSuccessUpdate({...successUpdate, create: false})}>
                {t('language.success.update')}
            </Alert>
            <Alert variant="success" show={successUpdate.translate} dismissible
                   onClose={() => setSuccessUpdate({...successUpdate, translate: false})}>
                {t('language.success.translate')}
            </Alert>
            <Alert variant="danger" show={errorUpdate.create} dismissible
                   onClose={() => setErrorUpdate({...errorUpdate, create: false})}>
                {t('language.error.update')}
            </Alert>
            <Alert variant="danger" show={errorUpdate.translate} dismissible
                   onClose={() => setErrorUpdate({...errorUpdate, translate: false})}>
                {t('language.error.translate')}
            </Alert>
            <Alert variant="danger" show={errorUpdate.translateApi} dismissible
                   onClose={() => setErrorUpdate({...errorUpdate, translateApi: false})}>
                {t('language.error.translateApi')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('language.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive className={"col-md-auto"}>
                        <thead>
                        <tr>
                            <th>
                                <span>{t('translation:general.fields.active')}</span>
                            </th>
                            <th>
                                <span>{t('translation:language.fields.showActive')}</span>
                            </th>
                            <th>
                                <span>{t('translation:language.translate')}</span>
                            </th>
                            <th>
                                <span>{t('translation:language.title')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {languages.length > 0 ? languages.map((language, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check type="switch" onChange={handleUpdateActiveLanguage(language)}
                                                    defaultChecked={language.active}>
                                        </Form.Check>
                                    </td>
                                    <td>
                                        <Form.Check type="switch" onChange={handleUpdateShowActiveLanguage(language)}
                                                    defaultChecked={language.showActive}>
                                        </Form.Check>
                                    </td>
                                    <td>
                                        <Button variant="link" size="ls"
                                                onClick={() => handleShow(language)}
                                                className={"border-primary me-2 " + ((language.showActive || language.numTranslations !== 0) && 'disabled')}>
                                            <FontAwesomeIcon icon="fas fa-language"/>
                                        </Button>
                                        {language.numTranslations !== 0 &&
                                            <div className={'d-inline'}>
                                                <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" size="lg" className={'ms-2 text-primary'}/>
                                                <span className={'ms-2 text-primary'}>{t('translation:language.fields.translationOnProgress')}</span>
                                            </div>
                                        }
                                    </td>
                                    <td>{language.languageName}</td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose} className="special_modal">
                <Modal.Header closeButton className={"bg-primary text-light text-center"}>
                    <Modal.Title className={"w-100"}>{t("translation:language.translate")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text className="justify-content-center">{t("translation:language.modal.origin")}</Form.Text>
                    <Form.Select
                        onChange={(e) => {
                            setOriginTranslate(parseInt(e.target.value));
                        }}>
                        <option value={-1}>-- Seleccionar idioma --</option>
                        {languages?.map((languageSelect, index) => {
                            return (languageAvoidSelect !== languageSelect) && (
                                <option key={index}
                                        value={languageSelect.languageId}>
                                    {languageSelect.languageName}
                                </option>
                            )
                        })}
                    </Form.Select>
                    <Form.Text>{t("translation:language.modal.destination")}</Form.Text>
                    <Form.Select onChange={(e) => {
                        setDestinationTranslate(parseInt(e.target.value));
                    }} defaultValue={languageAvoidSelect.languageId}>
                        {languages?.map((languageSelect, index) => {
                            return (
                                <option key={index}
                                        value={languageSelect.languageId}>
                                    {languageSelect.languageName}
                                </option>
                            )
                        })}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className={"text-light"}>
                        {t("translation:notificationModel.fields.cancel")}
                    </Button>
                    <Button variant="primary" onClick={handleShowConfirm} disabled={originTranslate === -1}>
                        {t("translation:notificationModel.fields.translate")}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm} className="special_modal">
                <Modal.Header closeButton className={"bg-primary text-light text-center"}>
                    <Modal.Title className={"w-100"}>{t("translation:language.translate")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={"text-center p-4"}>
                        {t("translation:notificationModel.translate")}
                        <span
                            className={"text-primary fw-bold"}>{languages[destinationTranslate - 1]?.languageName}</span>
                        {t("translation:notificationModel.translate2")}
                        <span className={"text-primary fw-bold"}>{languages[originTranslate - 1]?.languageName}</span>
                    </p>
                    <p className={"text-primary text-center fw-bold"}>
                        {t("translation:notificationModel.translateConfirm")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm} className={"text-light"}>
                        {t("translation:notificationModel.fields.cancel")}
                    </Button>
                    <Button variant="primary" onClick={translateAll}>
                        {t("translation:notificationModel.fields.translateConfirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Language;