import {trackPromise} from 'react-promise-tracker';
import axios from "axios";

import APP_CONF from "../scripts/constants";

import TokenService from "./token.service";

const instance = axios.create({
    baseURL: APP_CONF.BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && originalConfig.url !== "/auth/refresh" && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await instance.post("/auth/refresh", {
                        refreshToken: TokenService.getLocalRefreshToken(),
                    });

                    const {token} = rs.data;
                    TokenService.updateLocalToken(token);

                    return instance(originalConfig);
                } catch (_error) {
                    TokenService.removeUser();
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

const httpFunctions = {
    get: (url, params, options, spinner = true) => spinner ? trackPromise(instance.get(url, {...options, params})) : instance.get(url, {...options, params}),
    post: (url, data, options, spinner = true) => spinner ? trackPromise(instance.post(url, data)) : instance.post(url, data),
    put: (url, data, options, spinner = true) => spinner ? trackPromise(instance.put(url, data)) : instance.put(url, data),
    delete: (url, options, spinner = true) => spinner ? trackPromise(instance.delete(url)) : instance.delete(url),
};

export default httpFunctions;