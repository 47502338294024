import api from './api';

const getAll = () => {
    return api.get("banner").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`banner/${id}`).then((response) => {
        return response.data;
    })
}

const create = (banner) => {
    return api.post("banner", banner).then((response) => {
        return response.data;
    });
}

const update = (id, banner) => {
    return api.put(`banner/${id}`, banner).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`banner/${id}`).then((response) => {
        return response.data;
    })
}

const BannerService = {
    getAll,
    getById,
    create,
    update,
    deleteById,
}

export default BannerService;