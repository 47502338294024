import {useContext, useEffect, useState} from "react";
import {Alert, Card, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";

import RegionForm from "../../../components/forms/RegionForm";
import RegionService from "../../../services/region.service";
import BodyService from "../../../services/body.service";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const RegionDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const [region, setRegion] = useState({})

    const getRegion = async (id) => {
        try {
            const data = await RegionService.getById(id);
            setRegion(data);
        } catch (e) {
            update({...state, error: t("translation:region.error.notFound")})
            navigate("/region");
        }
    }

    const updateRegion = async (data) => {
        try {
            if (data.image?.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            await RegionService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
            getRegion(params.id);
        }
    }

    useEffect(() => {
        getRegion(params.id);
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {region?.name && <title>{region?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/region" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('region.title')}</span>
                </Link>
            </div>
            <Alert variant="success" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('translation:region.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('translation:region.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:region.titleDetails")}</h1>
                </Card.Header>
                <Card.Body>
                    <RegionForm region={region} onSubmit={updateRegion} details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default RegionDetails;
