import api from './api';

const getAll = (pagination) => {
    return api.get("category", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("category/list").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`category/${id}`).then((response) => {
        return response.data;
    })
}

const create = (category) => {
    return api.post("category", category).then((response) => {
        return response.data;
    });
}

const update = (id, category) => {
    return api.put(`category/${id}`, category).then((response) => {
        return response.data;
    })
}

const updateActive = (id, category) => {
    return api.put(`category/${id}/active`, category).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`category/${id}`).then((response) => {
        return response.data;
    })
}

const getAllByCategoryAndName = (familyId, categoryName, pagination) => {
    if (categoryName === null) {
        getAll(pagination);
    } else {
        return api.get("category/search/", {
            categoryName,
            familyId,
            pageNum: pagination.currentPage - 1,
            pageLength: pagination.itemsPerPage
        }).then((response) => {
            return response.data;
        })
    }
}

const CategoryService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    updateActive,
    deleteById,
    getAllByCategoryAndName,
}

export default CategoryService;