import React, {Suspense} from 'react';
import {render} from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './i18n';
import App from './App';
import {AppProvider} from './context/AppContext';
import {AlertConfirmProvider} from "./context/AlertConfirmContext";

const Providers = ({providers, children}) => {
    const renderProvider = (providers, children) => {
        const [provider, ...restProviders] = providers;

        if (provider) {
            return React.cloneElement(
                provider,
                null,
                renderProvider(restProviders, children)
            )
        }

        return children;
    }

    return renderProvider(providers, children)
}

render(
    <React.StrictMode>
        <Providers providers={[
            <AppProvider/>,
            <AlertConfirmProvider />
        ]}>
            <BrowserRouter>
                <Suspense fallback={null} name="crescent">
                    <App/>
                </Suspense>
            </BrowserRouter>
        </Providers>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
