import {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Card, Form, Image, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import APP_CONF from "../../../scripts/constants";
import AttributeTypeService from "../../../services/attributeType.service";
import AttributeTypeForm from "../../../components/forms/AttributeTypeForm";
import AccordionToggle from "../../../components/AccordionToggle";
import useAlertConfirm from "../../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import BodyService from "../../../services/body.service";
import {Helmet, HelmetData} from "react-helmet-async";

const AttributeType = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false,
        update: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        update: false,
        duplicate: false,
        linked: false
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: APP_CONF.ITEMS_PER_PAGE,
        numPages: 1
    });

    const [localType, setLocalType] = useState([]);
    const [translations, setTranslations] = useState([]);

    const createAttributeType = async (data) => {
        try {
            await AttributeTypeService.create(data);
            setSuccess({...success, create: true});
            setPagination({...pagination, currentPage: 1});
            accordionRef.current.click();
            getAttributeType();
        } catch (e) {
            console.error(e);
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, create: true});
            }
            throw e;
        }
    };

    const getAttributeType = async () => {
        const data = await AttributeTypeService.getAll(pagination);
        await setLocalType(data);
        setPagination({
            ...pagination,
            totalItems: data.totalElements,
            numPages: data.totalPages
        })
    }

    const getEmptyTranslationsName = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const deleteAttributeType = async (id) => {
        showModal({
            body: t("translation:notificationModel.attributeType"),
            onConfirm: async () => {
                try {
                    await AttributeTypeService.remove(id);
                    setSuccess({...success, delete: true});
                    getAttributeType();
                } catch (e) {
                    if (e.response.status === 409) {
                        setErrors({...errors, linked: true});
                    } else {
                        setErrors({...errors, delete: true});
                    }
                }
            }
        })
    }

    useEffect(() => {
        getAttributeType();
    }, [pagination.currentPage])

    useEffect(() =>{
        getEmptyTranslationsName();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:attribute.attributeTypes")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t("translation:attributeType.title")}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('attributeType.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('attributeType.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('attributeType.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('attributeType.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('translation:attributeType.error.duplicate')}
            </Alert>
            <Alert variant="danger" show={errors.linked} dismissible
                   onClose={() => setErrors({...errors, linked: false})}>
                {t('translation:attributeType.error.linked')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6
                            className={"m-0 fw-bold text-primary"}>{t('attributeType.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AttributeTypeForm translations={translations} onSubmit={createAttributeType}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('translation:attributeType.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col">{t('translation:general.index')}</th>
                            <th scope="col">
                                <span>{t('translation:general.fields.reference')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="referenceInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="referenceInfo" place="top" effect="solid">
                                    {t("translation:general.info.reference")}
                                </ReactTooltip>
                            </th>
                            <th scope="col" className="text-center col-2">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {localType.length > 0 ? localType.map((attributeType, index) => (
                                <tr key={index}>
                                    <td>{(index + (pagination.currentPage - 1) * pagination.itemsPerPage) + 1}</td>
                                    <td>{attributeType.name}</td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/attribute-type/" + attributeType.id}
                                                className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-1 ms-lg-2 border-primary" value={attributeType.id}
                                                onClick={() => deleteAttributeType(attributeType.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default AttributeType;
