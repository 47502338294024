import {t} from "i18next";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

const LocationForm = ({location, onSubmit, details}) => {

    const {register, handleSubmit, formState, reset} = useForm({mode: "all", defaultValues: {}});

    const onSubmitHandle = async (data) => {
        try {
            await onSubmit(data);
            if (!details) {
                reset();
            }
        } catch (e) {
            console.error(e)
        }
    }


    useEffect(() => {
        reset({
            ...location
        });
    }, [location])

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:location.creation.fields.infoFields')}
            </p>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Form.Label>{t('translation:location.creation.fields.name')}* :</Form.Label>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
            </Row>
            <Button type="submit" className={"mt-4"} disabled={!formState.isValid}>
                {!details ? t('translation:location.creation.buttonCreate') : t('translation:location.creation.buttonUpdate')}
            </Button>
        </Form>
    );
}

export default LocationForm;