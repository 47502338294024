import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AlertConfirmContext} from "../context/AlertConfirmContext";

const useAlertConfirm = () => {

    const {update} = useContext(AlertConfirmContext);

    const {t} = useTranslation();

    const showModal = ({title = t("translation:notificationDelete.fields.title"),
                           body = t("general.alertConfirm.body"),
                           confirmButtonContent = t("translation:notificationDelete.fields.title"),
                           confirmButtonVariant = "danger",
                           confirmButtonIsDark = true,
                           dismissButtonContent = t("translation:notificationDelete.fields.cancel"),
                           dismissButtonVariant = "secondary",
                           dismissButtonIsDark = true,
                           onConfirm = () => {},
                           onDismiss = () => {}}) => {
        update({
            show: true,
            title,
            body,
            confirmButtonContent,
            confirmButtonVariant,
            confirmButtonIsDark,
            dismissButtonContent,
            dismissButtonVariant,
            dismissButtonIsDark,
            onConfirm,
            onDismiss,
        });
    }

    const hideModal = () => {
        update({
            show: false,
            title: '',
            body: '',
            confirmButtonContent: '',
            confirmButtonVariant: '',
            dismissButtonContent: '',
            dismissButtonVariant: '',
            onConfirm: () => { },
            onDismiss: () => { }
        });
    }

    return {
        showModal,
        hideModal
    };
}

export default useAlertConfirm;