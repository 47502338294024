import api from './api';

const getAll = (pagination) => {
    return api.get("family", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("family/list").then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`family/${id}`).then((response) => {
        return response.data;
    })
}

const create = (family) => {
    return api.post("family", family).then((response) => {
        return response.data;
    });
}

const update = (id, family) => {
    return api.put(`family/${id}`, family).then((response) => {
        return response.data;
    })
}

const deleteById = (id) => {
    return api.delete(`family/${id}`).then((response) => {
        return response.data;
    })
}

const FamilyService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    deleteById
}

export default FamilyService;