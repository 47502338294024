import {Alert, Card, Container} from "react-bootstrap";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import CompanyService from "../../services/company.service";
import CompanyForm from "../../components/forms/CompanyForm";
import {Helmet, HelmetData} from "react-helmet-async";

const Company = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const updateCompany = async (data) => {
        try {
            await CompanyService.update(data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:general.sidebar.company")}</title>
            </Helmet>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t("company.success.update")}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t("company.error.update")}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:company.title")}</h1>
                </Card.Header>
                <Card.Body>
                    <CompanyForm onSubmit={updateCompany}/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Company;