import {Col, Form, FormGroup} from "react-bootstrap";

const TranslationInput = ({translations, onChange}) => {
    return (
        translations?.map((translation, index) => (
        <Col md="auto" key={index}>
            <FormGroup>
                <Form.Label>{translation.language.name}</Form.Label>
                <Form.Control type="text" value={translation?.value || ""}
                              onChange={(e) => onChange(index, e.target.value)}/>
            </FormGroup>
        </Col>
    )));
}

export default TranslationInput;