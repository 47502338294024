import api from './api';
import language from "../pages/language/Language";

const getAll = () => {
    return api.get("language").then((response) => {
        return response.data;
    });
}

const update = (id, tmpObject) => {
    return api.put(`language/${id}`, tmpObject).then((response) => {
        return response.data;
    })
}

const translate = (originTranslate, destinationTranslate) => {
    const translationLanguages = [originTranslate, destinationTranslate];
    return api.post(`language/translate`, translationLanguages).then((response) => {
        return response.data;
    })
}

const LanguageService = {
    getAll,
    update,
    translate
}

export default LanguageService;