import api from './api';

const getAll = (pagination) => {
    return api.get("offer", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getById = (id) => {
    return api.get(`offer/${id}`).then((response) => {
        return response.data;
    })
}

const create = (offer) => {
    return api.post("offer", offer).then((response) => {
        return response.data;
    });
}

const update = (id, offer) => {
    return api.put(`offer/${id}`, offer).then((response) => {
        return response.data;
    })
}

const remove = (id) => {
    return api.delete(`offer/${id}`).then((response) => {
        return response.data;
    })
}

const OfferService = {
    getAll,
    getById,
    create,
    update,
    remove
}

export default OfferService;