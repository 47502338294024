import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Card, Container} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../../context/AppContext";
import AttributeTypeService from "../../../../services/attributeType.service";
import AttributeTypeForm from "../../../../components/forms/AttributeTypeForm";
import BodyService from "../../../../services/body.service";
import {Helmet, HelmetData} from "react-helmet-async";

const AttributeTypeDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [attributeType, setAttributeType] = useState({});

    const {state, update} = useContext(AppContext);

    const [translations, setTranslations] = useState([]);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getAttributeType = useCallback(async (id) => {
        try {
            const data = await AttributeTypeService.getById(id);
            setAttributeType(data);
            setTranslations(data.nameTranslations);
        } catch (e) {
            update({...state, error: t("translation:attributeType.error.notFound")})
            navigate("/attribute-type");
        }
    }, [navigate]);

    const updateAttributeType = async (data) => {
        try {
            await AttributeTypeService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    const getEmptyTranslationsName = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    useEffect(async () => {
        await getAttributeType(params.id);
    }, [params.id]);

    useEffect(() => {
        getEmptyTranslationsName();
    }, []);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {attributeType?.name && <title>{attributeType?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/attribute-type" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t("translation:attributeType.title")}</span>
                </Link>
            </div>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t("translation:attributeType.success.update")}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t("translation:attributeType.error.update")}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:attributeType.title")}</h1>
                </Card.Header>
                <Card.Body>
                    <AttributeTypeForm translations={translations} attributeType={attributeType}
                                       onSubmit={updateAttributeType} details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default AttributeTypeDetails;