import {Navigate, Route, Routes} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {usePromiseTracker} from "react-promise-tracker";
import React from "react";

import Language from "./pages/language/Language";
import Local from "./pages/local/Local";
import LocalDetails from "./pages/local/details/LocalDetails";
import LocalType from "./pages/local/type/LocalType"
import LocalTypeDetails from "./pages/local/type/details/LocalTypeDetails"
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import Region from "./pages/region/Region";
import RegionDetails from "./pages/region/details/RegionDetails";
import Family from "./pages/family/Family";
import FamilyDetails from "./pages/family/details/FamilyDetails";
import FamilyCategory from "./pages/family/familyCategory/FamilyCategory";
import Category from "./pages/category/Category";
import CategoryDetails from "./pages/category/details/CategoryDetails";
import Offer from "./pages/offer/Offer";
import OfferDetails from "./pages/offer/details/OfferDetails";
import Attribute from "./pages/attribute/Attribute";
import AttributeDetails from "./pages/attribute/details/AttributeDetails";
import Location from "./pages/location/Location";
import LocationDetails from "./pages/location/details/LocationDetails";
import Product from "./pages/product/Product";
import ProductDetails from "./pages/product/details/ProductDetails";
import Banner from "./pages/banner/Banner";
import BannerDetails from "./pages/banner/details/BannerDetails";
import CategoryProduct from "./pages/category/categoryProduct/CategoryProduct";
import AttributeType from "./pages/attribute/type/AttributeType";
import AttributeTypeDetails from "./pages/attribute/type/details/AttributeTypeDetails";
import Company from "./pages/company/Company";
import "react-datepicker/dist/react-datepicker.css";
import './scss/index.scss';

const App = () => {

    const {promiseInProgress} = usePromiseTracker();

    return (
        <>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/" element={<Main/>}>
                    <Route path="language" element={<Language/>}/>
                    <Route path="local" element={<Local/>}/>
                    <Route path="local/:id" element={<LocalDetails/>}/>
                    <Route path="local-type" element={<LocalType/>}/>
                    <Route path="local-type/:id" element={<LocalTypeDetails/>}/>
                    <Route path="region" element={<Region/>}/>
                    <Route path="region/:id" element={<RegionDetails/>}/>
                    <Route path="family" element={<Family/>}/>
                    <Route path="family/:id" element={<FamilyDetails/>}/>
                    <Route path="family-category/:id" element={<FamilyCategory/>}/>
                    <Route path="category" element={<Category/>}/>
                    <Route path="category/:id" element={<CategoryDetails/>}/>
                    <Route path="category-product/:id" element={<CategoryProduct/>}/>
                    <Route path="offer" element={<Offer/>}/>
                    <Route path="offer/:id" element={<OfferDetails/>}/>
                    <Route path="attribute" element={<Attribute/>}/>
                    <Route path="attribute/:id" element={<AttributeDetails/>}/>
                    <Route path="attribute-type" element={<AttributeType/>}/>
                    <Route path="attribute-type/:id" element={<AttributeTypeDetails/>}/>
                    <Route path="location" element={<Location/>}/>
                    <Route path="location/:id" element={<LocationDetails/>}/>
                    <Route path="product" element={<Product/>}/>
                    <Route path="product/:id" element={<ProductDetails/>}/>
                    <Route path="banner" element={<Banner/>}/>
                    <Route path="banner/:id" element={<BannerDetails/>}/>
                    <Route path="company" element={<Company/>}/>
                </Route>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
            {promiseInProgress &&
            <div className="spinner-overlay d-flex justify-content-center align-items-center">
                <Spinner animation="border" className="spinner-center" variant="light" size="xl" role="status"/>
            </div>
            }
        </>
    );
};

export default App;
