import api from './api';

const getAllByCategory = (id) => {
    return api.get(`product/category/${id}/list`).then((response) => {
        return response.data;
    });
}

const update = (id, productCategoryList) => {
    return api.put(`category/${id}/order`, productCategoryList, null, false).then((response) => {
        return response.data;
    })
}

const CategoryProductService = {
    getAllByCategory,
    update
}

export default CategoryProductService;
