import {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Alert, Card, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ProductService from "../../../services/product.service";
import ProductForm from "../../../components/forms/ProductForm";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const ProductDetails = () => {

    const helmetData = new HelmetData({});

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [product, setProduct] = useState()
    const [nameTranslations, setNameTranslations] = useState([]);
    const [descriptionTranslations, setDescriptionTranslations] = useState([]);
    const [halfPortionTranslations, setHalfPortionTransalations] = useState([]);
    const [imageList, setImageList] = useState([]);

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getProduct = async (id) => {
        try {
            const data = await ProductService.getById(id);
            setProduct(data);
            if (data.productImages.length === 0) {
                const list = [{order: 1}];
                setImageList(list);
            } else {
                setImageList(data.productImages);
            }
            setNameTranslations(data.nameTranslations);
            setDescriptionTranslations(data.descriptionTranslations);
            setHalfPortionTransalations(data.halfPortionTranslations);
        } catch (e) {
            console.error(e);
            update({...state, error: t("translation:product.error.notFound")})
            navigate("/product");
        }
    }

    const updateProduct = async (data) => {
        try {
            data.productImages = imageList;
            data.nameTranslations = nameTranslations;
            data.descriptionTranslations = descriptionTranslations;
            data.halfPortionTranslations = halfPortionTranslations;
            await ProductService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            console.error(e);
            setErrors({...errors, update: true});
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getProduct(params.id);
        }
        fetchData();
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {product?.name && <title>{product?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/product" className={"previous round rounded-circle text-decoration-none"}
                      title={t('general.back')}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t('product.title')}</span>
                </Link>
            </div>
            <Alert variant="success" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t('product.success.update')}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t('product.error.update')}
            </Alert>
            <Card>
                <Card.Header className={"py-3"}>
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("product.title")}</h1>
                </Card.Header>
                <Card.Body>
                    <ProductForm
                        product={product}
                        onSubmit={updateProduct}
                        translations={{
                            nameTranslations: product?.nameTranslations,
                            descriptionTranslations: product?.descriptionTranslations,
                            halfPortionTranslations: product?.halfPortionTranslations
                        }}
                        details
                        imageList={imageList}
                        setImageList={setImageList}/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default ProductDetails;
