import {Button, Col, Form, FormGroup, Image, Row} from "react-bootstrap";
import BodyService from "../../services/body.service";
import {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import LocalTypeService from "../../services/localType.service";
import RegionService from "../../services/region.service";
import LocationService from "../../services/location.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {v4 as uuidv4} from "uuid";
import DatePicker from "react-datepicker";
import {useTranslation} from "react-i18next";

const LocalForm = ({local, onSubmit, details}) => {

    const {t} = useTranslation();

    const [base64, setBase64] = useState();
    const [defBase64, setDefBase64] = useState();
    const [pdfBase64, setPdfBase64] = useState();
    const [localDetails, setLocalDetails] = useState({});
    const [regions, setRegions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [localTypes, setLocalTypes] = useState([]);
    const [regionSelected, setRegionSelected] = useState(details);
    const [locationSelected, setLocationSelected] = useState(details);
    const [typeSelected, setTypeSelected] = useState(details);
    const [activeValuation, setActiveValuation] = useState(false);
    const [scheduleList, setScheduleList] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        uuid: uuidv4(),
        hours: [{startHour: "", endHour: "", scheduleDay: 0, uuid: uuidv4()}]
    }]);
    const [scheduleHolidayList, setScheduleHolidayList] = useState([]);

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const [years, setYears] = useState([]);
    const generateYears = () => {
        if (years.length < 1) {
            let copyYears = years;
            for (let i = 0; i <= 10; i++) {
                copyYears.push(new Date().getFullYear() + i);
            }
            setYears(copyYears);
        }
    }

    const scheduleDays = [{
        id: 8,
        name: t("translation:local.creation.fields.scheduleDay.allWeek")
    }, {
        id: 9,
        name: t("translation:local.creation.fields.scheduleDay.weekDay")
    }, {
        id: 10,
        name: t("translation:local.creation.fields.scheduleDay.weekEnd")
    }, {
        id: 1,
        name: t("translation:local.creation.fields.scheduleDay.monday")
    }, {
        id: 2,
        name: t("translation:local.creation.fields.scheduleDay.tuesday")
    }, {
        id: 3,
        name: t("translation:local.creation.fields.scheduleDay.wednesday")
    }, {
        id: 4,
        name: t("translation:local.creation.fields.scheduleDay.thursday")
    }, {
        id: 5,
        name: t("translation:local.creation.fields.scheduleDay.friday")
    }, {
        id: 6,
        name: t("translation:local.creation.fields.scheduleDay.saturday")
    }, {
        id: 0,
        name: t("translation:local.creation.fields.scheduleDay.sunday")
    }];

    const {register, handleSubmit, formState, reset, setValue} = useForm({mode: "all"});

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const setImageDefToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setDefBase64(base);
        }
    }

    const setPdfToBase64 = async (application) => {
        if (application) {
            const base = await BodyService.getBase64(application);
            setPdfBase64(base);
        }
    };

    const removeImage = () => {
        setBase64(null);
        setValue('image', null);
        setValue('imageUrl', null);
        if (localDetails) {
            setLocalDetails({...localDetails, imageUrl: null});
        }
    }

    const removeDefImage = () => {
        setValue("imageDefault", null);
        setValue("imageDefUrl", null);
        if (localDetails) {
            setLocalDetails({...localDetails, imageDefUrl: null});
        }
    }

    const removePdf = () => {
        setPdfBase64(null);
        setValue("cartaPdf", null);
        setValue("cartaPdfUrl", null);
        if (localDetails) {
            setLocalDetails({...localDetails, cartaPdfUrl: null});
        }
    }

    const getLocalTypes = async () => {
        const data = await LocalTypeService.getAllList();
        setLocalTypes(data);
    }

    const getRegions = async () => {
        const data = await RegionService.getAllList();
        setRegions(data);
    }

    const getLocations = async () => {
        const data = await LocationService.getAllList();
        setLocations(data);
    }

    const addSchedule = () => {
        const copyScheduleList = [...scheduleList];
        copyScheduleList.push({
            startDate: new Date(),
            endDate: new Date(),
            uuid: uuidv4(),
            hours: [{startHour: "", endHour: "", scheduleDay: 0, uuid: uuidv4()}]
        })
        setScheduleList(copyScheduleList);
    }

    const addScheduleHoliday = () => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        copyScheduleHolidayList.push({
            date: new Date(),
            uuid: uuidv4(),
            startHour: "",
            endHour: "",
            active: true,
            openCalendar: false
        })
        setScheduleHolidayList(copyScheduleHolidayList);
    }

    const handleOpenCalendar = (openCalendar, index) => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        copyScheduleHolidayList[index].openCalendar = openCalendar;
        setScheduleHolidayList(copyScheduleHolidayList);
    }

    const removeScheduleHoliday = (index) => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        if (scheduleHolidayList.length === 1) {
            setScheduleHolidayList([]);
        } else {
            copyScheduleHolidayList.splice(index, 1);
            setScheduleHolidayList(copyScheduleHolidayList);
        }
    }

    const setDateRange = (dates, index) => {
        const [startDate, endDate] = dates;
        const copyScheduleList = [...scheduleList];
        startDate.setHours(12);
        if (endDate != null) {
            endDate.setHours(12);
        }
        copyScheduleList[index].startDate = startDate;
        copyScheduleList[index].endDate = endDate;
        setScheduleList(copyScheduleList);
    }

    const setDateHolidayRange = (date, index) => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        copyScheduleHolidayList[index].date = date;
        copyScheduleHolidayList[index].openCalendar = false;
        setScheduleHolidayList(copyScheduleHolidayList);
    }

    const addHour = async (index) => {
        const copyScheduleList = [...scheduleList];
        copyScheduleList[index].hours.push({startHour: "", endHour: "", scheduleDay: 0, uuid: uuidv4()});
        setScheduleList(copyScheduleList);
    }

    const removeHour = async (index, indexH) => {
        const copyScheduleList = [...scheduleList];
        if (scheduleList[index].hours.length === 1) {
            copyScheduleList.splice(index, 1);
        } else {
            copyScheduleList[index].hours.splice(indexH, 1);
        }
        setScheduleList(copyScheduleList);
    }

    const setDay = (day, index, indexH) => {
        const copyScheduleList = [...scheduleList];
        copyScheduleList[index].hours[indexH].day = day;
        setScheduleList(copyScheduleList);
    }

    const setStartHour = (time, index, indexH) => {
        const copyScheduleList = [...scheduleList];
        copyScheduleList[index].hours[indexH].startHour = time;
        setScheduleList(copyScheduleList);
    }

    const setStartHolidayHour = (e, index) => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        copyScheduleHolidayList[index].startHour = e.target.value;
        setScheduleHolidayList(copyScheduleHolidayList);
    }

    const setEndHour = (time, index, indexH) => {
        const copyScheduleList = [...scheduleList];
        copyScheduleList[index].hours[indexH].endHour = time;
        setScheduleList(copyScheduleList);
    }

    const setEndHolidayHour = (e, index) => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        copyScheduleHolidayList[index].endHour = e.target.value;
        setScheduleHolidayList(copyScheduleHolidayList);
    }

    const setActiveHoliday = (active, index) => {
        const copyScheduleHolidayList = [...scheduleHolidayList];
        copyScheduleHolidayList[index].active = active;
        setScheduleHolidayList(copyScheduleHolidayList);
    }

    const convertToDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(5, 7);
        const day = dateString.substring(8, 10);
        return new Date(year, month - 1, day, 12, 0, 0);
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        Object.keys(data).forEach(key => {
            if (data[key] === "") data[key] = null;
        });
        data.scheduleList = scheduleList;
        scheduleList.forEach((schedule) => {
            schedule.hours.forEach((hour, index) => {
                if (hour.day === undefined) {
                    schedule.hours.splice(index, 1);
                }
            })
        });
        data.scheduleHolidayList = scheduleHolidayList;
        scheduleHolidayList.forEach(scheduleHoliday => {
            if (scheduleHoliday.startHour === "") {
                scheduleHoliday.startHour = null;
            }
            if (scheduleHoliday.endHour === "") {
                scheduleHoliday.endHour = null;
            }
        });
        data.activeValuation = activeValuation;
        await onSubmit(data);
        setRegionSelected(false);
        setLocationSelected(false);
        setTypeSelected(false);
        setScheduleList([{
            startDate: new Date(),
            endDate: new Date(),
            uuid: uuidv4(),
            hours: [{startHour: "", endHour: "", scheduleDay: 0, uuid: uuidv4()}]
        }]);
        if (!details) {
            reset();
            removeImage();
            removeDefImage();
        }
    }

    useEffect(() => {
        if (!details || (details && local)) {
            const fetchData = () => {
                getRegions();
                getLocations();
                getLocalTypes();
                reset({
                    ...local
                });
                setLocalDetails(local);
                setRegionSelected(local?.region);
                setLocationSelected(local?.location);
                setTypeSelected(local?.type);
                setActiveValuation(local?.activeValuation);
                if (!(local?.scheduleList[0]?.startDate == null)) {
                    setScheduleList(
                        local?.scheduleList.map((schedule) => ({
                            ...schedule,
                            startDate: convertToDate(schedule.startDate),
                            endDate: convertToDate(schedule.endDate)
                        }))
                    );
                }
                if (!(local?.scheduleHolidayList[0]?.date == null)) {
                    setScheduleHolidayList(
                        local?.scheduleHolidayList.map((scheduleHoliday) => ({
                            ...scheduleHoliday,
                            date: convertToDate(scheduleHoliday.date)
                        }))
                    );
                }
            }
            fetchData();
        }
    }, [local]);

    useEffect(() => {
        generateYears();
    }, [])

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:local.creation.fields.infoFields')}
            </p>
            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo"
                             className="infoDialog m-0"/>
            <ReactTooltip id="referenceInfo" place="right" effect="solid">
                {t("translation:local.creation.fields.localInfo")}
            </ReactTooltip>
            <Row className="mb-3 mt-1">
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.region')}*</Form.Label>
                        <Form.Select {...register("region", {required: true})}
                                     onChange={() => setRegionSelected(true)}>
                            {(!regionSelected || !details) && <option value=""></option>}
                            {regions?.map(region => (
                                <option key={region.id} value={region.id}>{region.name}</option>
                            ))}
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('translation:local.creation.fields.location')}*</Form.Label>
                        <Form.Select {...register("location", {required: true})}
                                     onChange={() => setLocationSelected(true)}>
                            {(!locationSelected || !details) && <option value=""></option>}
                            {locations?.map(location => (
                                <option key={location.id} value={location.id}>{location.name}</option>
                            ))}
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.type')}*</Form.Label>
                        <Form.Select {...register("type", {required: true})}
                                     onChange={() => setTypeSelected(true)}>
                            {!typeSelected && <option value=""></option>}
                            {localTypes?.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.activeValuation')}</Form.Label>
                        <Form.Check className="mt-1" type="switch"
                                    checked={activeValuation} onChange={() => {
                            setActiveValuation(!activeValuation);
                        }}/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <Row className="mb-3">
                <Col md="auto mt-2">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.name')}* :</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="nameInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="nameInfo" place="right" effect="solid">
                            {t("translation:local.creation.fields.nameInfo")}
                        </ReactTooltip>
                        <Form.Control type="text" {...register("name", {required: true})}/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="extraInfo"
                             className="infoDialog m-0"/>
            <ReactTooltip id="extraInfo" place="right" effect="solid">
                {t("translation:local.creation.fields.extraInfo")}
            </ReactTooltip>
            <Row>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.province')} :</Form.Label>
                        <Form.Control type="text" {...register("province")}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.postalCode')} :</Form.Label>
                        <Form.Control type="text" {...register("postalCode", {
                            minLength: 5,
                            maxLength: 5
                        })}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.dir1')} :</Form.Label>
                        <Form.Control type="text" {...register("dir1")}/>
                    </FormGroup>
                </Col>
                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('translation:local.creation.fields.dir2')} :</Form.Label>
                        <Form.Control type="text" {...register("dir2")}/>
                    </FormGroup>
                </Col>

                <Col md="auto" className="mb-2 mb-md-0">
                    <FormGroup>
                        <Form.Label>{t('local.creation.fields.phone')} :</Form.Label>
                        <Form.Control type="text" {...register("phone")}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="auto mt-3">
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.image')}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="right" effect="solid">
                            {t('translation:local.creation.fields.imageInfo')}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image")}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-3">
                {(localDetails?.imageUrl || base64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <Image fluid thumbnail src={base64 || local?.imageUrl} alt={local?.name}/>
                </Col>
                }
                {(base64 || localDetails?.imageUrl) && (
                    <Col className="align-self-end">
                        <Button type={"button"} variant="link" size="ls" onClick={removeImage}
                                className="border-primary">
                            <FontAwesomeIcon icon="fas fa-trash"/>
                        </Button>
                    </Col>
                )}
            </Row>
            <hr/>

            {/*Imagen default*/}

            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:local.creation.fields.imagenDefault")}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageDefInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageDefInfo" place="right" effect="solid">
                            {t("translation:local.creation.fields.imageDefaultInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("imageDefault", {required: false})}
                                      onChange={(e) => setImageDefToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="my-2">
                {(localDetails?.imageDefUrl || defBase64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <Image fluid thumbnail src={defBase64 || local?.imageDefUrl} alt={local?.name}/>
                </Col>
                }
                {(defBase64 || localDetails?.imageDefUrl) &&
                <Col className="align-self-end">
                    <Button type={"button"} variant="link" size="ls" onClick={removeDefImage}
                            className="border-primary">
                        <FontAwesomeIcon icon="fas fa-trash"/>
                    </Button>
                </Col>
                }
            </Row>
            <hr/>
            {/* PDF */}
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:local.creation.fields.pdf")}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="pdfInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="pdfInfo" place="right" effect="solid">
                            {t("translation:local.creation.fields.pdfInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="application/pdf"
                                      {...register("cartaPdf", {required: false})}
                                      onChange={(e) => setPdfToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="my-2">
                {(localDetails?.cartaPdfUrl || pdfBase64) &&
                <Col className="col-8 col-md-6 col-lg-2">
                    <FontAwesomeIcon icon="fas fa-file-pdf" size="5x" className="ms-1"/>
                </Col>
                }
                {(pdfBase64 || localDetails?.cartaPdfUrl) &&
                <Col className="align-self-end">
                    <Button type={"button"} variant="link" size="ls" onClick={removePdf}
                            className="border-primary">
                        <FontAwesomeIcon icon="fas fa-trash"/>
                    </Button>
                </Col>
                }
            </Row>
            <hr/>

            {/* Horario */}

            <FormGroup>
                <Form.Label>{t("translation:local.creation.fields.schedule")}</Form.Label>
                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="schedule"
                                 className="infoDialog"/>
                <ReactTooltip id="schedule" place="right" effect="solid">
                    {t("translation:local.creation.fields.scheduleInfo")}
                </ReactTooltip>
            </FormGroup>
            {scheduleList?.map((schedule, index) => (
                <Fragment key={schedule.id || schedule.uuid}>
                    <Row className="mt-1">
                        <Col md="auto">
                            <Form.Label>
                                {t('translation:local.creation.fields.date')} :
                            </Form.Label>
                        </Col>
                        <Col md="auto">
                            <p className="text-center">
                                {new Date(schedule?.startDate).toLocaleDateString() + " " +
                                t('translation:local.creation.fields.to') + " " +
                                new Date(schedule?.endDate).toLocaleDateString()}
                            </p>
                            <DatePicker
                                renderCustomHeader={({date, changeYear, changeMonth,}) => (
                                    <div
                                        style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <select
                                            defaultValue={new Date(schedule.startDate).getFullYear()}
                                            onChange={({target: {value}}) => changeYear(value)}
                                        >
                                            {years?.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            defaultValue={months[new Date(schedule.startDate).getMonth()]}
                                            onChange={({target: {value}}) =>
                                                changeMonth(months.indexOf(value))
                                            }
                                        >
                                            {months.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                openToDate={schedule.date}
                                startDate={schedule.startDate}
                                endDate={schedule.endDate}
                                selectsRange
                                shouldCloseOnSelect={false}
                                onChange={(dates) => setDateRange(dates, index)}
                                calendarStartDay={1}
                                inline

                            />
                        </Col>
                        <Col>
                            {schedule.hours?.map((hour, indexH) => (
                                <Fragment key={hour.id || hour.uuid}>
                                    <Row className={indexH !== 0 && "mt-3"}>
                                        <Col md="auto" className="d-flex align-items-end">
                                            <Form.Label>{t('translation:local.creation.fields.day')}:</Form.Label>
                                        </Col>
                                        <Col md="auto">
                                            <Form.Select value={hour?.day}
                                                         onChange={(event) => setDay(parseInt(event.target.value), index, indexH)}>
                                                {!hour?.day && <option></option>}
                                                {scheduleDays.map(scheduleDay => (
                                                    <option key={scheduleDay.id}
                                                            value={scheduleDay.id}>{scheduleDay.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col md="auto" className="d-flex align-items-end">
                                            <FormGroup>
                                                <Form.Label>{t('translation:local.creation.fields.startHour')} :</Form.Label>
                                                <input className="ms-3" type="time"
                                                       onBlur={(event) => setStartHour(event.target.value, index, indexH)}
                                                       defaultValue={hour.startHour}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md="auto" className="d-flex align-items-end">
                                            <FormGroup>
                                                <Form.Label>{t('translation:local.creation.fields.endHour')} :</Form.Label>
                                                <input className="ms-3" type="time"
                                                       onBlur={(event) => setEndHour(event.target.value, index, indexH)}
                                                       defaultValue={hour.endHour}/>
                                            </FormGroup>
                                        </Col>
                                        {(scheduleList.length !== 1 || scheduleList[0].hours.length !== 1) &&
                                        <Col className="align-self-end">
                                            <Button type={"button"} variant="link" size="ls" onClick={() => removeHour(index, indexH)}>
                                                <FontAwesomeIcon icon="fas fa-trash"/>
                                            </Button>
                                        </Col>
                                        }
                                    </Row>
                                    {(indexH === schedule.hours?.length - 1) &&
                                    <Row className={"mt-2"}>
                                        <Col md="auto">
                                            <FormGroup onClick={() => addHour(index)}>
                                                <Form.Label
                                                    style={{cursor: "pointer"}}>{t('translation:local.creation.fields.addHour')}</Form.Label>
                                                <Button type={"button"} variant="link" size="lg" className={"ms-2"}>
                                                    <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    }
                                </Fragment>
                            ))}
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col md="auto">
                            {(index === scheduleList.length - 1 && schedule.endDate) &&
                            <FormGroup onClick={() => addSchedule()}>
                                <Form.Label
                                    style={{cursor: "pointer"}}>{t('translation:local.creation.fields.addDate')}</Form.Label>
                                <Button type={"button"} variant="link" size="lg" className={"ms-2"}>
                                    <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                                </Button>
                            </FormGroup>
                            }
                        </Col>
                    </Row>
                </Fragment>
            ))}
            <hr/>

            {/* Horario Festivo */}

            <FormGroup>
                <Form.Label>{t("translation:local.creation.fields.scheduleHoliday")}</Form.Label>
                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="scheduleHoliday"
                                 className="infoDialog"/>
                <ReactTooltip id="scheduleHoliday" place="right" effect="solid">
                    {t("translation:local.creation.fields.scheduleHolidayInfo")}
                </ReactTooltip>
            </FormGroup>
            {scheduleHolidayList?.map((scheduleHoliday, index) => (
                <Fragment key={scheduleHoliday.id || scheduleHoliday.uuid}>
                    <Row className="mt-1">
                        <Col md="auto" className="mt-2">
                            <Form.Label>
                                {t('translation:local.creation.fields.date')} :
                            </Form.Label>
                        </Col>
                        <Col className={"col-auto"}>
                            <button type={"button"} className={"btn-primary rounded"} onClick={() =>
                                handleOpenCalendar(!scheduleHoliday.openCalendar, index)
                            }>
                                {new Date(scheduleHoliday?.date).toLocaleDateString()}
                            </button>
                            {scheduleHoliday.openCalendar && (
                                <DatePicker
                                    renderCustomHeader={({date, changeYear, changeMonth,}) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <select
                                                defaultValue={new Date(scheduleHoliday.date).getFullYear()}
                                                onChange={({target: {value}}) => changeYear(value)}
                                            >
                                                {years?.map((option) => (
                                                    <option key={"holiday" + option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                defaultValue={months[new Date(scheduleHoliday.date).getMonth()]}
                                                onChange={({target: {value}}) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    selected={scheduleHoliday.date}
                                    onChange={(date) => setDateHolidayRange(date, index)}
                                    calendarStartDay={1}
                                    inline
                                />
                            )}
                        </Col>
                        <Col>
                            <Row>
                                <Col md="auto"
                                     className="d-flex align-items-center">{t('translation:general.fields.active')}</Col>
                                <Col md="auto" className="d-flex align-items-center">
                                    <Form.Check className="mt-1" type="switch" checked={scheduleHoliday.active}
                                                onChange={() => {
                                                    setActiveHoliday(!scheduleHoliday.active, index);
                                                }}
                                    />
                                </Col>
                                <Col md="auto" className="d-flex align-items-end" key={scheduleHoliday.id}>
                                    <Form.Label>{t('translation:local.creation.fields.startHour')} :</Form.Label>
                                    <input className="ms-3 mb-1" type="time"
                                           onBlur={(event) => setStartHolidayHour(event, index)}
                                           defaultValue={scheduleHoliday.startHour}/>
                                </Col>
                                <Col md="auto" className="d-flex align-items-end">
                                    <Form.Label>{t('translation:local.creation.fields.endHour')} :</Form.Label>
                                    <input className="ms-3 mb-1" type="time"
                                           onBlur={(event) => setEndHolidayHour(event, index)}
                                           defaultValue={scheduleHoliday.endHour}/>
                                </Col>
                                <Col className="align-self-end">
                                    <Button type={"button"} variant="link" size="ls" onClick={() => removeScheduleHoliday(index)}>
                                        <FontAwesomeIcon icon="fas fa-trash"/>
                                    </Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Fragment>
            ))}
            <Row className="mt-1">
                <Col md="auto">
                    <FormGroup onClick={() => addScheduleHoliday()}>
                        <Form.Label
                            style={{cursor: "pointer"}}>{t('translation:local.creation.fields.addDate')}</Form.Label>
                        <Button type={"button"} variant="link" size="lg" className={"ms-2"}>
                            <FontAwesomeIcon icon="fa-solid fa-square-plus"/>
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            <div className="mt-4">
                {(!formState.isValid) &&
                <p className="infoDisabledButton">
                    {t('translation:attribute.creation.fields.requiredFields')}
                </p>
                }
                <Button type="submit" disabled={!formState.isValid}>
                    {!details ? t('translation:local.creation.buttonCreate') : t('translation:local.creation.buttonUpdate')}
                </Button>
            </div>
        </Form>
    );
}

export default LocalForm;