import {t} from "i18next";
import {Alert, Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import TranslationInput from "./fields/TranslationInput";

const AttributeTypeForm = ({attributeType, translations, onSubmit, details}) => {

    const {register, handleSubmit, formState, reset} = useForm({mode: "all", defaultValues: {}});

    const [nameTranslations, setNameTranslations] = useState([]);
    const [showAlerts, setShowAlerts] = useState(false);

    const setTranslationValue = (index, value) => {
        const copyNameTranslations = [...nameTranslations];
        copyNameTranslations[index].value = value;
        setNameTranslations(copyNameTranslations);
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        data.nameTranslations = nameTranslations;
        await onSubmit(data);
        if (!details) {
            reset();
            nameTranslations.forEach((translation, index) => {
                setTranslationValue(index, "");
            })
        }
    }

    useEffect(() => {
        if (!details || (details && attributeType !== {})) {
            setNameTranslations(translations);
        }
    }, [translations]);

    useEffect(() => {
        if (!details || (details && attributeType)) {
            reset({
                ...attributeType
            });
            setShowAlerts(true);
        }
    }, [attributeType]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:attributeType.creation.fields.infoFields')}
            </p>
            <Row>
                <Col xs={12} sm={3} className="mb-2 mb-sm-0">
                    <FormGroup>
                        <Form.Label>{t("translation:general.fields.reference")}* :</Form.Label>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
                <Col xs={12} sm={3}>
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                        <Form.Check className="mt-1" type="switch"
                                    defaultChecked={!details ? false : attributeType?.active} {...register("active")}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    {translations?.length > 0 ?
                        <Row className="mb-3">
                            <Col>
                                <label className="my-2">{t("translation:general.fields.nameTranslations")} :</label>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="translationInfo" place="right" effect="solid">
                                    {t("translation:attributeType.creation.fields.translationInfo")}
                                </ReactTooltip>
                                <Row>
                                    <TranslationInput translations={nameTranslations} onChange={setTranslationValue}/>
                                </Row>
                            </Col>
                        </Row>
                        :
                        <Alert variant="danger" className="mt-2" show={showAlerts}>
                            {t('translation:general.emptyTranslations')}
                        </Alert>
                    }
                </Col>
            </Row>
            <Button type="submit" className={"mt-3"} disabled={!formState.isValid}>
                {!details ? t("translation:attributeType.creation.buttonCreate") : t("translation:attributeType.creation.buttonUpdate")}
            </Button>
        </Form>
    );
}

export default AttributeTypeForm;