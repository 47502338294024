import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const DeleteModal = ({show, onDismiss, onSubmit, children}) => {

    const {t} = useTranslation();

    return(
        <Modal show={show} onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>{t("translation:notificationDelete.fields.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" style={{color: 'white'}} onClick={onSubmit}>
                    {t("translation:notificationDelete.fields.title")}
                </Button>
                <Button variant="secondary" style={{color: 'white'}} onClick={onDismiss}>
                    {t("translation:notificationDelete.fields.cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteModal;