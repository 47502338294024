import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Card, Container} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FamilyService from "../../../services/family.service";
import FamilyForm from "../../../components/forms/FamilyForm";
import {AppContext} from "../../../context/AppContext";
import {Helmet, HelmetData} from "react-helmet-async";

const FamilyDetails = () => {

    const helmetData = new HelmetData({})

    const params = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    const [family, setFamily] = useState();
    const [translations, setTranslations] = useState([]);

    const {state, update} = useContext(AppContext);

    const [success, setSuccess] = useState({
        update: false,
    });

    const [errors, setErrors] = useState({
        update: false,
    });

    const getFamily = useCallback(async (id) => {
        try {
            const data = await FamilyService.getById(id);
            setFamily(data);
            setTranslations(data.nameTranslations);
        } catch ({response}) {
            update({...state, error: t("translation:family.error.notFound")})
            navigate("/family");
        }
    }, [navigate]);

    const updateFamily = async (data) => {
        try {
            await FamilyService.update(params.id, data);
            setSuccess({...success, update: true});
        } catch (e) {
            setErrors({...errors, update: true});
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getFamily(params.id);
        }
        fetchData();
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet helmetData={helmetData}>
                {family?.name && <title>{family?.name}</title>}
            </Helmet>
            <div className={"d-flex justify-content-between my-3"}>
                <Link to="/family" className={"previous round rounded-circle text-decoration-none"}
                      title={t("general.back")}>
                    <FontAwesomeIcon icon={"chevron-left"} className={"mt-2 me-2"}/>
                    <span>{t("family.title")}</span>
                </Link>
            </div>
            <Alert variant="primary" show={success.update} dismissible
                   onClose={() => setSuccess({...success, update: false})}>
                {t("family.success.update")}
            </Alert>
            <Alert variant="danger" show={errors.update} dismissible
                   onClose={() => setErrors({...errors, update: false})}>
                {t("family.error.update")}
            </Alert>
            <Card>
                <Card.Header className="py-3">
                    <h1 className={"h3 mb-0 text-gray-800 card-title"}>{t("translation:family.titleDetails")}</h1>
                </Card.Header>
                <Card.Body>
                    <FamilyForm family={family}
                                translations={translations}
                                onSubmit={updateFamily}
                                details/>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default FamilyDetails;