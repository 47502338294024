import {t} from "i18next";
import {useEffect, useRef, useState} from "react";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import TranslationInput from "./fields/TranslationInput";

const OfferForm = ({offer, translations, onSubmit, details}) => {

    const [nameTranslations, setNameTranslations] = useState([]);

    const {register, watch, handleSubmit, formState, reset} = useForm({
        mode: "all",
        defaultValues: {},
    });

    const startDate = useRef(null);
    const endDate = useRef(null);

    startDate.current = watch("startDate");
    endDate.current = watch("endDate");

    const setTranslationValue = (index, value) => {
        setNameTranslations(nameTranslations.map((translation, i) => {
            if (i === index) {
                translation.value = value;
            }
            return translation;
        }));
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid) return;
        data.nameTranslations = nameTranslations;
        await onSubmit(data);
        if (!details) {
            reset();
        }
    }

    useEffect(() => {
        if (!details || (details && offer)) {
            reset({
                ...offer
            });
        }
    }, [offer])

    useEffect(() => {
        if(!details || (details && offer !== {})) {
            setNameTranslations(translations);
        }
    }, [translations]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.reference')}* :</Form.Label>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:offer.creation.fields.startDate')}*</Form.Label>
                        <Form.Control type="date" {...register("startDate", {
                            required: true,
                            valueAsDate: true,
                            validate: value => value <= endDate.current
                        })}/>
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:offer.creation.fields.endDate')}*</Form.Label>
                        <Form.Control type="date" {...register("endDate", {
                            required: true,
                            valueAsDate: true,
                            validate: value => value >= startDate.current
                        })}/>
                    </FormGroup>
                </Col>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t('translation:general.fields.active')}</Form.Label>
                        <Form.Check className="mt-1" type="switch"
                                    defaultChecked={offer === undefined ? true : offer.active} {...register("active")}/>
                    </FormGroup>
                </Col>
            </Row>
            <label className="mt-4 mb-2">{t('translation:general.fields.nameTranslations')} :</label>
            <Row>
                <TranslationInput translations={nameTranslations} onChange={setTranslationValue} />
            </Row>
            <Button type="submit" className="mt-4" disabled={!formState.isValid}>
                {!details ? t('translation:offer.creation.buttonCreate') : t('translation:offer.creation.buttonUpdate')}
            </Button>
        </Form>
    );
}

export default OfferForm;