import api from './api';

const getAll = (pagination) => {
    return api.get("local", {
        pageNum: pagination.currentPage - 1,
        pageLength: pagination.itemsPerPage
    }).then((response) => {
        return response.data;
    });
}

const getAllList = () => {
    return api.get("local/list").then((response) => {
        return response.data;
    })
}

const getById = (id) => {
    return api.get(`local/${id}`).then((response) => {
        return response.data;
    });
}

const create = (local) => {
    return api.post("local", local).then((response) => {
        return response.data;
    });
}

const update = (id, tmpObject) => {
    return api.put(`local/${id}`, tmpObject).then((response) => {
        return response.data;
    })
}

const remove = (id) => {
    return api.delete(`local/${id}`).then((response) => {
        return response.data;
    })
}

const LocalService = {
    getAll,
    getAllList,
    getById,
    create,
    update,
    remove,
}

export default LocalService;