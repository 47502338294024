import {Accordion, Alert, Button, Card, Form, Image, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import BannerForm from "../../components/forms/BannerForm";
import BodyService from "../../services/body.service";
import BannerService from "../../services/banner.service";
import AccordionToggle from "../../components/AccordionToggle";
import LanguageService from "../../services/languages.service";
import useAlertConfirm from "../../hooks/useAlertConfirm";
import ReactTooltip from "react-tooltip";
import {Helmet, HelmetData} from "react-helmet-async";

const Banner = () => {

    const helmetData = new HelmetData({});

    const {t} = useTranslation();

    const {showModal} = useAlertConfirm();

    const [banners, setBanners] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [languages, setLanguages] = useState([]);
    const accordionRef = useRef(null);

    const [success, setSuccess] = useState({
        create: false,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        delete: false,
        duplicate: false
    });

    const createBanner = async (data) => {
        try {
            data.descriptionTranslations = translations;
            if (data.image.length > 0) {
                data.imageName = data.image[0].name;
                data.imageBase64 = await BodyService.getBase64(data.image[0]);
                data.imageBase64 = data.imageBase64.split(',')[1];
                data.imageContentType = data.image[0].type;
            }
            delete data.image;
            await BannerService.create(data);
            setSuccess({...success, create: true});
            accordionRef.current.click();
            getEmptyTranslationsName();
            getBanners();
        } catch (e) {
            if (e.response.status === 406) {
                setErrors({...errors, duplicate: true});
            } else {
                setErrors({...errors, create: true});
            }
            throw e;
        }
    };

    const getEmptyTranslationsName = async () => {
        const data = await BodyService.getEmptyTranslations();
        setTranslations(data);
    }

    const getBanners = async () => {
        const data = await BannerService.getAll();
        await setBanners(data);
    }

    const getLanguages = async () => {
        const data = await LanguageService.getAll();
        setLanguages(data);
    }

    const deleteBanner = async (id) => {
        showModal({
            body: t("translation:notificationModel.banner"),
            onConfirm: async () => {
                try {
                    await BannerService.deleteById(id);
                    setSuccess({...success, delete: true});
                    getBanners();
                } catch (e) {
                    setErrors({...errors, delete: true});
                }
            }
    })
}

    useEffect( () => {
        getBanners();
        getEmptyTranslationsName();
        getLanguages();
    }, []);

    return (
        <>
            <Helmet helmetData={helmetData}>
                <title>{t("translation:banner.banners")}</title>
            </Helmet>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <h1 className={"h3 mb-0 text-gray-800"}>{t('translation:banner.title')}</h1>
            </div>
            <Alert variant="success" show={success.create} dismissible
                   onClose={() => setSuccess({...success, create: false})}>
                {t('banner.success.create')}
            </Alert>
            <Alert variant="success" show={success.delete} dismissible
                   onClose={() => setSuccess({...success, delete: false})}>
                {t('banner.success.delete')}
            </Alert>
            <Alert variant="danger" show={errors.create} dismissible
                   onClose={() => setErrors({...errors, create: false})}>
                {t('banner.error.create')}
            </Alert>
            <Alert variant="danger" show={errors.delete} dismissible
                   onClose={() => setErrors({...errors, delete: false})}>
                {t('banner.error.delete')}
            </Alert>
            <Alert variant="danger" show={errors.duplicate} dismissible
                   onClose={() => setErrors({...errors, duplicate: false})}>
                {t('banner.error.duplicate')}
            </Alert>
            <Card className={"shadow mb-4"}>
                <Accordion>
                    <AccordionToggle eventKey="0" toggleRef={accordionRef}/>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 className={"m-0 fw-bold text-primary"}>{t('banner.creation.title')}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <BannerForm bannerLength={banners.length} translations={translations} onSubmit={createBanner}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
            <Card className={"shadow mb-4"}>
                <Card.Header className={"py-3"}>
                    <h6 className={"m-0 fw-bold text-primary"}>{t('banner.list.title')}</h6>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th scope="col" className={"col-1"}>
                                <span>{t('translation:banner.list.order')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:general.fields.image')}</span>
                            </th>
                            <th scope="col">
                                <span>{t('translation:banner.list.descriptionTitle')}</span>
                                <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip
                                                 data-for="descriptionInfo"
                                                 className="infoDialog"/>
                                <ReactTooltip id="descriptionInfo" place="top" effect="solid">
                                    {t("translation:general.info.language")}
                                </ReactTooltip>
                            </th>
                            <th scope="col" className="text-center">
                                <span>{t('translation:general.fields.actions')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {banners?.length > 0 ? banners?.map((banner, index) => (
                                <tr key={index}>
                                    <td>{banner.order}</td>
                                    <td className="w-25">
                                        {banner.imageUrl &&
                                        <>
                                            <Image fluid thumbnail src={banner.imageUrl} alt={banner.name} className="d-none d-md-flex w-50"/>
                                            <Image fluid thumbnail src={banner.imageUrl} alt={banner.name} className="d-flex d-md-none"/>
                                        </>
                                        }
                                    </td>
                                    <td>
                                        {BodyService.printTranslations(languages, banner.descriptionTranslations, index)}
                                    </td>
                                    <td className={"text-center"}>
                                        <Button variant="link" size="sm" as={Link}
                                                to={"/banner/" + banner.id} className="border-primary">
                                            <FontAwesomeIcon icon="edit"/>
                                        </Button>
                                        <Button variant="link" size="sm" className="ms-0 ms-md-2 border-primary" value={banner.id}
                                                onClick={() => deleteBanner(banner.id)}>
                                            <FontAwesomeIcon icon="fas fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={4}>{t('general.empty')}</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default Banner;
