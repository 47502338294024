import {t} from "i18next";
import {Alert, Button, Col, Form, FormGroup, Image, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import BodyService from "../../services/body.service";
import Select from "react-select";
import LocalService from "../../services/local.service";
import {useForm} from "react-hook-form";
import FamilyService from "../../services/family.service";
import TranslationInput from "./fields/TranslationInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const FamilyForm = ({family, translations, onSubmit, details}) => {

    const [nameTranslations, setNameTranslations] = useState([]);
    const [familyDetails, setFamilyDetails] = useState({});
    const [familyList, setFamilyList] = useState([]);
    const [locals, setLocals] = useState([]);
    const [localsSelected, setLocalsSelected] = useState([]);
    const [base64, setBase64] = useState();
    const [showAlerts, setShowAlerts] = useState(false);

    const {register, handleSubmit, formState, reset, getValues, setValue} = useForm({mode: "all"});

    const getLocals = async () => {
        const data = await LocalService.getAllList();
        setLocals(data);
    }

    // Se deja la función de get Families para poder implementar en un futuro el poder poner que una familia pueda
    // depender de otra.
    const getFamilies = async () => {
        const data = await FamilyService.getAllList();
        setFamilyList(data);
    }

    const setTranslationValue = (index, value) => {
        const copyNameTranslations = [...nameTranslations];
        copyNameTranslations[index].value = value;
        setNameTranslations(copyNameTranslations);
    }

    const setImageToBase64 = async (file) => {
        if (file) {
            const base = await BodyService.getBase64(file);
            setBase64(base);
        }
    }

    const removeImage = () => {
        setBase64(null);
        setValue("image", null);
        setValue("imageUrl", null);
        if (family) {
            setFamilyDetails({...family, imageUrl: null});
        }
    }

    const setFormLocals = (local) => {
        setValue(`locals`, local);
        setLocalsSelected(getValues("locals"));
    }

    const onSubmitHandle = async (data) => {
        if (!formState.isValid || !localsSelected.length > 0) return;
        data.nameTranslations = nameTranslations;

        if (data.image.length > 0) {
            data.imageName = data.image[0].name;
            data.imageBase64 = await BodyService.getBase64(data.image[0]);
            data.imageBase64 = data.imageBase64.split(',')[1];
            data.imageContentType = data.image[0].type;
        }
        delete data.image;

        await onSubmit(data);
        if (!details) {
            setLocalsSelected([]);
            removeImage();
            getFamilies();
            reset();
            nameTranslations.forEach((translation, index) => {
                setTranslationValue(index, "");
            })
        }
    }

    useEffect(() => {
        if (!details || (details && family)) {
            getFamilies();
            getLocals();
            reset({
                ...family
            });
            setFamilyDetails(family);
            setLocalsSelected(family?.locals || [])
            setShowAlerts(true);
        }
    }, [family]);

    useEffect(() => {
        if (!details || (details && family !== {})) {
            setNameTranslations(translations);
        }
    }, [translations]);

    return (
        <Form onSubmit={handleSubmit(onSubmitHandle)}>
            <p className="text-info">
                {t('translation:family.creation.fields.infoFields')}
            </p>
            <Row className="mb-2">
                <Col md="auto">
                    <FormGroup controlId="formFamilyName">
                        <Form.Label>{t("translation:family.creation.fields.reference")}* :</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="referenceInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="referenceInfo" place="right" effect="solid">
                            {t("translation:family.creation.fields.referenceInfo")}
                        </ReactTooltip>
                        <Form.Control type="text" {...register("name", {required: true, minLength: 3, maxLength: 50})}/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="translationInfo"
                             className="infoDialog m-0"/>
            <ReactTooltip id="translationInfo" place="right" effect="solid">
                {t("translation:family.creation.fields.translationInfo")}
            </ReactTooltip>
            {translations?.length > 0 ?
                <Row>
                    <label className="mb-2">{t("translation:general.fields.nameTranslations")} :</label>
                    <Row>
                        <TranslationInput translations={nameTranslations} onChange={setTranslationValue}/>
                    </Row>
                </Row>
                :
                <Alert variant="danger" className="mt-2" show={showAlerts}>
                    {t('translation:general.emptyTranslations')}
                </Alert>
            }
            <hr/>
            <Row className="my-3">
                {/*No está implementada la familia padre, se deja comentado el código por si se implementa más adelante*/}

                {/*<Col md="auto">*/}
                {/*    <FormGroup controlId="formFamilyList">*/}
                {/*        <Form.Label>{t("translation:family.creation.fields.parentFamily")} :</Form.Label>*/}
                {/*        <Form.Select {...register("parentFamilyId")}>*/}
                {/*            <option value=""/>*/}
                {/*            {familyList.map((family) => (*/}
                {/*                (!details && (family.id !== familyDetails?.id)) &&*/}
                {/*                (<option key={family.id} value={family.id}>{family.name}</option>)*/}
                {/*            ))}*/}
                {/*        </Form.Select>*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                <Col md="6" lg="4">
                    <Form.Label>{t("translation:family.creation.fields.local")}* :</Form.Label>
                    <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="localInfo"
                                     className="infoDialog"/>
                    <ReactTooltip id="localInfo" place="right" effect="solid">
                        {t("translation:family.creation.fields.localInfo")}
                    </ReactTooltip>
                    {locals.length > 0 &&
                    <Select options={locals}
                            isMulti={true}
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            placeholder=""
                            value={localsSelected}
                            noOptionsMessage={() => t("general.empty")}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            onChange={setFormLocals}/>
                    }
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Form.Label>{t("translation:general.fields.image")}</Form.Label>
                        <FontAwesomeIcon icon="fas fa-question-circle" size="sm" data-tip data-for="imageInfo"
                                         className="infoDialog"/>
                        <ReactTooltip id="imageInfo" place="right" effect="solid">
                            {t("translation:family.creation.fields.imageInfo")}
                        </ReactTooltip>
                        <Form.Control type="file"
                                      accept="image/png, image/jpeg"
                                      {...register("image")}
                                      onChange={(e) => setImageToBase64(e.target.files[0])}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Row className="mt-3">
                    {(familyDetails?.imageUrl || base64) &&
                    <Col className="col-8 col-md-6 col-lg-2">
                        <Image fluid thumbnail src={base64 || family?.imageUrl} alt={family?.name}/>
                    </Col>
                    }
                    {(base64 || familyDetails?.imageUrl) && (
                        <Col className="align-self-end">
                            <Button variant="link" size="ls" onClick={removeImage}
                                    className="border-primary">
                                <FontAwesomeIcon icon="fas fa-trash"/>
                            </Button>
                        </Col>
                    )}
                </Row>
            </Row>
            <div className="mt-4">
                {(!formState.isValid || !localsSelected.length > 0) &&
                <p className="infoDisabledButton">
                    {t('translation:family.creation.fields.requiredFields')}
                </p>
                }
                <Button type="submit" disabled={!formState.isValid || !localsSelected.length > 0}>
                    {!details ? t("translation:family.creation.buttonCreate") : t("translation:family.creation.buttonUpdate")}
                </Button>
            </div>
        </Form>
    );
}

export default FamilyForm;